import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import logo from "../../../../logo.svg"
import "./Match.css"
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import LoaderComp from "../../../loader/Loader";
import { useIsReverseResult } from "../../../context/isReverseMatchingResult";
import EmailToCandidate from "./Email/EmailJob";
import CardDetailsJob from "./CardDetails/CardDetailsJob";
import EmailClient from "../../DirectMatching/Match/Email/Email";
import IQG from "../../DirectMatching/Match/IQG/IQG";
import { format } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { useIsInfoPage } from "../../../context/isInfoPage";
import { useInfoString } from "../../../context/infoString";
import InfoPage from "../../Info/InfoPage";


function TableMatches(props: any) {
  const {toggleView } = useIsReverseResult();
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [matchDetails, setMatchDetails] = useState<any>({});
  const [candidateDetails, setCandidateDetails] = useState({});
  const [dropdown, setDropdown] = useState(null);
  const [quit, setQuit] = useState(false);

  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
  const { infoString, setInfoString } = useInfoString();

  const { accounts } = useMsal();
  const findJob = async (associate_id:any) => {
    console.log(associate_id === "")
    let headersTemp:any = {};
    let expTemp:any = {}
    let rowTemp:any = {};
    props.headers.forEach((value:any) => {
      if (value === 'Codice_Fiscale') {

        headersTemp[value] = 'ID Candidato'
      }

      else if (value === 'Stato_Candidato') {
        headersTemp[value] = 'Stato Candidato'
      }

      else if (value === 'Stato_Collaboratore') {
        headersTemp[value] ='Stato Collaboratore'
      }

      else if (value === 'last_certification_date') {
        headersTemp[value] ='Data ultima certificazione'
      }

      else if (value === 'name') {
        headersTemp[value] ='Nome'
      }

      else if (value === 'surname') {
        headersTemp[value] ='Cognome'
      }

      else if (value === 'home_city') {
        headersTemp[value] ='Città domicilio'
      }

      else if (value === 'home_province') {
        headersTemp[value] ='Provincia domicilio'
      }

      else if (value === 'Classe_Eta') {
        headersTemp[value] ='Classe età'
      }

      else if (value === 'professional_categories_profile') {
        headersTemp[value] ='Categorie di interesse'
      }

      else if (value === 'last_5_group_prof_cat_applications') {
        headersTemp[value] ='Ultime 5 cat. application'
      }

      else if (value === 'matters_known') {
        headersTemp[value] ='Skill'
      }

      else if (value === 'regulated_trainings') {
        headersTemp[value] ='Education'
      }

      else if (value === 'languages') {
        headersTemp[value] ='Lingue'
      }

      else if (value === 'driving_licenses') {
        headersTemp[value] ='Patenti'
      }

      else if (value === 'last_5_clients') {
        headersTemp[value] ='Ultimi 5 clienti'
      }

      else if (value === 'register_date') {
        headersTemp[value] ='Data registrazione'
      }

      else if (value === 'last_activity_date') {
        headersTemp[value] ='Data ultima attività'
      }

      else if (value === 'has_disadvantage') {
        headersTemp[value] ='Svantaggiato' 
      }

      else if (value === 'CDC_DI') {
        headersTemp[value] ='CDC D&I'
      }

      else if (value === 'Categoria_protetta') {
        headersTemp[value] ='Categoria protetta'
      }

      else if (value === 'Type_handicap_percentage') {
        headersTemp[value] ='Disabilità'
      }

      else if (value === 'most_recent_activity') {
        headersTemp[value] ='Attività più recente'
      }

      else if (value === 'sl_bench') {
        headersTemp[value] ='Collaboratore SL'
      }

      props.setHeadersNew(headersTemp)

      for (const element in props.row) {
        console.log(props.row.element)
        if (element && element === 'last_activity_date' || element === 'register_date' || element === 'last_certification_date') {
          rowTemp[element] = format(new Date(props.row[element]), "yyyy-MM-dd")
        }
        else {
          rowTemp[element] = props.row[element]
        }
      }
      props.setRowValues(rowTemp)
    })
    fetch(`/api/databricks/cvcand?associate_id=${associate_id}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json' } })
      .then(response => response.json())
      .then(function(cv) {
        if (associate_id === "") {
          props.setMatches([])
        }
        else {
          fetch(`/api/databricks/findJob?associate_id=${associate_id}&username=${accounts[0]['username']}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(jobs) {
              props.setMatches(jobs)
              console.log(jobs)
              if (jobs.length === 0) {
                setTimeElapsed(true)
              }
              for (let i=0; i<=Math.min(4,jobs.length-1); i++) {
                fetch(`/api/databricks/jobdesc?announcement_id=${jobs[i].job_id}`, {
                  method: 'GET',
                  headers: { 'Accept': 'application/json' } })
                  .then(response => response.json())
                  .then(function(jobDesc) {
                    if (cv['cv'] !== "" && jobDesc['job'] !== "") {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                   "Content-Type": "application/json"
                         },
                        body: JSON.stringify({cv:cv['cv'],jobDescription:jobDesc['job'],motivo_match:jobs[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(explain) {
                          expTemp[jobs[i].job_id] = explain['spiegazione']
                        })
                    }
                    else {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                  "Content-Type": "application/json"},
                        body: JSON.stringify({cv:"",jobDescription:"",motivo_match:jobs[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(explainElse) {
                          expTemp[jobs[i].job_id] = explainElse['spiegazione']
                        })
                    }
                    console.log(expTemp)
                    props.setExplain(expTemp)
                  })
              }
            })
        }
      })
  }

  const toggleDropdown = async (id:any,match:any) => {
    console.log(match.job_id)
    setDropdown((prev) => {
      if (prev === id) {
        return null
      }
      else {
        return id
      }
    });

    if (!(match.job_id in props.explain)) {
      let expTemp:any = props.explain
      fetch(`/api/databricks/jobdesc?announcement_id=${match.job_id}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(job) {
          fetch(`/api/databricks/cvcand?associate_id=${props.associate_id}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(cv) { 
              if (cv['cv'] !== "" && job['job'] !== "") {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                             "Content-Type": "application/json"
                   },
                  body: JSON.stringify({cv:cv['cv'],jobDescription:job['job'],motivo_match:match.Motivo_Match}) })
                  .then(response => response.json())
                  .then(function(explain) {
                      expTemp[match.job_id] = explain['spiegazione']})
             }

             else {
              fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                method: 'POST',
                headers: { 'Accept': 'application/json',
                          "Content-Type": "application/json"},
                body: JSON.stringify({cv:"",jobDescription:"",motivo_match:match.Motivo_Match})})
                .then(response => response.json())
                .then(function(explainElse) {
                  expTemp[match.job_id] = explainElse['spiegazione']
                })
             }
             props.setExplain(expTemp)
            }) 
          })
      }
  };

  const toggleCheck = (id:any) => {
    let partial = props.checkList;
    if (props.checkList.includes(id)) {
      partial = partial.filter(function(e:any) { return e !== id })
      props.setCheckList(partial)
      
    }
    else {
      props.setCheckList([...props.checkList,id])
    }

    console.log(props.checkList)
  };

  const getBack = () => {
    props.setMatches([])
    setInfoString(
      <div>
        <span style={{ color: "red", fontWeight:"bold" }}><h2>Candidati disponibili </h2></span>
        <p><h3>Benvenuto nella home page del GPT Talent Placer.
                In questa sezione puoi utilizzare l’algoritmo di matching per le migliori posizioni lavorative per un candidato.
                Nella tabella principale trovi tutti i candidati disponibili ed inseriti a sistema entro le due ore precedenti. Utilizzando i filtri presenti nel menù a scomparsa sulla sinistra puoi cercare il candidato di tuo interesse. Inoltre, nell’angolo in alto a sinistra della tabella puoi trovare alcune funzionalità che ti permetteranno di filtrare i dati per una specifica colonna, nascondere le colonne che non ti interessa visualizzare e modificare la visualizzazione della tabella stessa.
                Una volta individuato il candidato di interesse, clicca sul tasto “Matching” per visualizzare le posizioni lavorative più affini. I risultati mostreranno le posizioni lavorative in un intorno (in linea d’aria) di 25km dal domicilio del candidato.
                Se, invece, il candidato non è presente a sistema clicca sul bottone “Candidati disponibili” per passare alla pagina “Definisci le caratteristiche del candidato”

                </h3></p>
      </div>
    )
    setQuit(true);
    props.setCvs("")
    toggleView(true);
  }

  const generateQuestion = (match:any) => {
    console.log(match["professional_category"])
    setMatchDetails(match);
    props.setIqg(true);
  }

  const getDetails = (fiscale:any, match:any) => {
    props.setCodFisc(fiscale);
    setMatchDetails(match);
    props.setJobInfo(true);
    props.setOrdine(false);
    props.setIntervista(false);
    props.setCv(false);
    props.setDetails(true);
  }

  const generateEmail = (match: any) => {
    setMatchDetails(match);
    props.setJobId(match.job_id)
    props.setEmailC(true);
  }

  const generateEmailToJob = (match:any) => {
    setMatchDetails(match);
    props.setJobId(match.job_id)
    setCandidateDetails({'Motivo_Match':match['Motivo_Match'],'Distanza_km':match['distance_km']});
    props.setEmail(true);
  }


  useEffect(() => {
    setInfoString(<div>
      <span style={{ color: "red", fontWeight:"bold" }}><h2>Risultati ricerca Talent Placer</h2></span>
      <p><h3>In questa pagina trovi i risultati della ricerca effettuata. 
              Sulla sinistra trovi un riassunto delle caratteristiche del candidato. 
              Le schede, invece, mostrano i risultati (posizioni lavoratice) ordinati per score decrescente (quindi dal più affine al meno affine). Ogni scheda della posizione lavorativa riporta alcune informazioni rilevanti come: 
            <ul>
              <li>ID Offerta (composta da ORD/OFF in base al fatto si parli di offerte pubblicate o ordini interni, numero ordine / offerta, CDC)</li>
              <li>Categoria professionale della posizione lavorativa</li>
              <li>Job title della posizione lavorativa</li>
              <li>Cliente</li>
              <li>Distanza domicilio – luogo di lavoro</li>
            </ul>
            Inoltre, è presente un riquadro che permettere di “spuntare” le posizioni lavorative già visualizzate. Il flag rimane attivo per l’intera sessione.
            In basso ad ogni scheda, sono presenti 4 bottoni:
            <ul>
              <li>Dettagli, che reindirizza ad una pagina di dettaglio contenente le informazioni della posizione lavorativa, gli step della pipeline in cui è stato inserito il candidato da Gennaio 2023 ad oggi, le interviste effettuate dai recruiter da Gennaio 2023 ad oggi ed il CV</li>
              <li>Email Candidato, che permette di creare una mail di presentazione della posizione lavorativa al candidato</li>
              <li>Genera Domande, che permette di generare le domande per il colloquio con il candidato per la posizione lavorativa</li>
              <li>Email cliente, permette di generare la mail per presentare il candidato al cliente per la posizione lavorativa specifica</li>
            </ul>
          </h3>
        </p>
    </div>)
    if (props.matches.length === 0) {
      findJob(props.associate_id);
    }
  }, [])


  return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
      <div>
      {(props.email)?
        <EmailClient email={props.email} setEmail={props.setEmail} candidate={candidateDetails} candidateId={props.associate_id} jobId={props.jobId} category={matchDetails["professional_category"]} workCity={props.row["home_city"]} emailsC={props.emails} setEmailsC={props.setEmails}></EmailClient>
      :
      <div>
      {(props.emailC) ?
      <div>
        <EmailToCandidate email={props.emailC} setEmail={props.setEmailC} job={matchDetails} candidate={props.associate_id} candidateId={props.associate_id} jobId={props.jobId} emails={props.emails} setEmails={props.setEmails}></EmailToCandidate>
      </div>
      :
      <div>
        {(props.isDetails) 
        ?
          <div>
            <div>
              <CardDetailsJob cvs={props.cvs} setCvs={props.setCvs} codFisc={props.row['Codice_Fiscale']} 
              job={matchDetails} urlPDF={props.urlPDF} setUrlPDF={props.setUrlPDF} 
              setCodFisc={props.setCodFisc} 
              toggleView = {toggleView} isDetails = {props.isDetails}  setDetails={props.setDetails}
              jobId={props.jobId}
              setJobId={props.setJobId}
              jobInfos={props.jobInfos}
              setJobInfos={props.setJobInfos}
              interviste={props.interviste}
              setInterviste={props.setInterviste}
              ordini={props.ordini}
              setOrdini={props.setOrdini}
              jobInfo={props.jobInfo}
              setJobInfo={props.setJobInfo}
              ordine={props.ordine}
              setOrdine={props.setOrdine}
              intervista={props.intervista}
              setIntervista={props.setIntervista}
              cv={props.cv}
              setCv={props.setCv}
              timeJobInfo={props.timeJobInfo}
              setTimeJobInfo={props.setTimeJobInfo}
              timeOrdine={props.timeOrdine}
              setTimeOrdine={props.setTimeOrdine}
              timeIntervista={props.timeIntervista}
              setTimeIntervista={props.setTimeIntervista}
              timeCvsJob={props.timeCvsJob}
              setTimeCvsJob={props.setTimeCvsJob}
              isAI={props.isAI}
              setIsAI={props.setIsAI}

              ></CardDetailsJob>
            </div>
          </div>
        : 
        <div>
        {(props.iqg) ?
          <div>
            <IQG category={matchDetails["professional_category"]} iqg={props.iqg} setIqg={props.setIqg} candidateId={props.associate_id} jobId={props.jobId} questions={props.questions} setQuestions={props.setQuestions}></IQG>    
          </div>
          :
        <div>
          <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
            </div>
          </div>
          {(props.matches.length > 0 || timeElapsed)? 
            <div className="row-main">
              <div className="column-table-direct">
                {props.headers.filter((item:any) => {
                let toExclude = [
                 
                ]
                if (props.row[item] === "N/A" || props.row[item] === null) {
                  toExclude.push(item)
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
                <div className="inline-div-super-dir">
                    <h2 className="test-h3">{props.headersNew[header]}</h2>
                    <h5>{props.rowValues[header]}</h5>
                </div>
                ))}
              </div>
              <div className="sovra-container-direct">
              {(timeElapsed &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                  {props.matches.map((match: any) => (
                    <div key={match.ID_Univoco} className="candidate-card-dir">
                      <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(match.job_id)} checked={props.checkList.includes(match.job_id)}></input>
                      <label className="label-for-check">Visualizzato</label>
                      <div className="sub-container-lr">
                        <div className="section-infos-right-dir">
                          <p><strong>ID Offerta: </strong>{match.job_id}</p>
                          <p><strong>Cat. Prof.: </strong>{match.professional_category}</p>
                          <p><strong>Job Title: </strong>{match.job_title}</p>
                          {(match.client_desc && <p><strong>Cliente: </strong>{match.client_desc}</p>)}
                          <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {props.row.home_city} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {match["work_city"]} </strong>pari a {Math.round(Number(match.Distanza_km))} KM</p>
                          
                        </div>
                        <div className="section-infos-left">
                        <p
                            className= {
                              match.announcement_type === "OFFER" 
                                                        ? "blue-tag" 
                                                        : match.announcement_type === "ORDER"
                                                        ? "orange-tag"
                                                        : ""
                            }
                            >
                            {match.announcement_type}
                          </p>                        
                        </div>
                      </div>
                      <div id="dropdown-full">
                        <div 
                          key={match.job_id}
                          className={`dropdown-slot ${dropdown === match.job_id ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(match.job_id,match)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === match.job_id ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === match.job_id ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === match.job_id ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                            {dropdown === match.job_id? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === match.job_id ? '' : 'match-reason-hidden'}`}>
                        <span className={`match-reason ${dropdown === match.job_id ? '' : 'match-reason-hidden'}`}>
                            {(props.explain[match.job_id] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                        </span>
                          {dropdown === match.job_id? props.explain[match.job_id]: ""}
                        </div>
                        <div className="row-button">
                          <button className="cta-red-mat" onClick={() => getDetails(match.associateid,match)}>
                            {"Dettagli"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmail(match)}>
                            {"Email Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateQuestion(match)}>
                            {"Genera Domande"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmailToJob(match)}>
                            {"Email Cliente"}
                          </button>
                        </div>
                      </div>
                  </div>
                ))}
            </div>
            </div>
          : 
          <div className="div-center">
            <div>
              <h3>Ricerca posizioni lavorative in corso</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
          </div>}
          </div>
          }
          </div>
          }
          </div>
          }
          </div>
          }
          <div style={{textAlign:"center"}}>
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
                </div>
        </div>
        }
      </div>
  )

}

export default TableMatches;