import "./Sidebar.css"
import {useState} from 'react'
import Select from 'react-select'
import usePopulateField from "../../../hooks/usePopulateFields";
import { _post } from "../../../../apiClient";
import CreatableSelect from "react-select/creatable";
import { useMsal } from "@azure/msal-react";

const Sidebar = (props:any) => {
  const [jobData, setJobData] = useState<string[]>([]);
  const [branchData, setBranchData] = useState<string[]>([]);
  const [CDCData, setCDCData] = useState<string[]>([]);
  const [blData, setBlData] = useState<string[]>([]);
  const [clientData, setclientData] = useState<string[]>([]);
  const [skillData, setSkillData] = useState<string[]>([]);
  const [opsData, setOpsData] = useState<string[]>([]);
  const [regData, setRegData] = useState<string[]>([]);
  const [idJob, setIdJob] = useState<string[]>([]);
  const [selectedOptionJob, setSelectedOptionJob] = useState<[]>([]);
  const [selectedOptionBranch, setSelectedOptionBranch] = useState<[]>([]);
  const [selectedOptionCDC, setSelectedOptionCDC] = useState<[]>([]);
  const [selectedOptionBl, setSelectedOptionBl] = useState<[]>([]);
  const [selectedOptionClient, setSelectedOptionClient] = useState<[]>([]);
  const [selectedOptionSkill, setSelectedOptionSkill] = useState<[]>([]);
  const [selectedOptionOps, setSelectedOptionOps] = useState<[]>([]);
  const [selectedOptionReg, setSelectedOptionReg] = useState<[]>([]);
  const [selectedOptionIdJob, setSelectedOptionIdJob] = useState<[]>([]);

  const { accounts } = useMsal();

  usePopulateField("/api/databricks/filterJob", setJobData);
  usePopulateField("/api/databricks/filterBranch", setBranchData);
  usePopulateField("/api/databricks/filterBL", setBlData);
  usePopulateField("/api/databricks/filterClient", setclientData);
  usePopulateField("/api/databricks/filterSkill", setSkillData);
  usePopulateField("/api/databricks/filterOPS", setOpsData);
  usePopulateField("/api/databricks/filterREG", setRegData);
  usePopulateField("/api/databricks/filterCDC", setCDCData);
  usePopulateField("/api/databricks/filterIDJob", setIdJob);

  let filtersObject: Record<string, any> = {'announcement_id': selectedOptionIdJob, "professional_category":selectedOptionJob,
    'work_city':selectedOptionBranch,'type_business_line':selectedOptionBl,'client_desc':selectedOptionClient,
    'skills':selectedOptionSkill,'zona_desc':selectedOptionOps,'dop_desc':selectedOptionReg, 'branch':selectedOptionCDC};
  

  const handleTypeSelectIdJob = (e:any) => {
    setSelectedOptionIdJob(e);
  };

  const handleTypeSelectJob = (e:any) => {
    setSelectedOptionJob(e)
  };

  const handleTypeSelectBranch = (e:any) => {
    setSelectedOptionBranch(e);
  };

  const handleTypeSelectBl = (e:any) => {
    setSelectedOptionBl(e);
  };

  const handleTypeSelectClient = (e:any) => {
    setSelectedOptionClient(e);
  };

  const handleTypeSelectSkill = (e:any) => {
    setSelectedOptionSkill(e);
  };

  const handleTypeSelectOps = (e:any) => {
    setSelectedOptionOps(e);
  };

  const handleTypeSelectReg = (e:any) => {
    setSelectedOptionReg(e);
  };

  const handleTypeSelectCDC = (e:any) => {
    setSelectedOptionCDC(e);
  };

  const sendBack = async (s:any) => {
    let selectedFilters: any = {'isFiltered':true,'username':accounts[0]['username'],'announcement_id':"",'professional_category':"",'work_city':"",'type_business_line':"",'client_desc':"",'skills':"",'zona_desc':"",'dop_desc':"",'branch':""}
    props.setJ([]);
    props.timeE(false);
    for (const tag in selectedFilters) {
      if (tag === 'isFiltered' || tag === 'username') continue;
      if (filtersObject[tag].length !== 0) {
        if (tag === 'skills') {
          let tupleString = ''
          for (const pc of filtersObject[tag]) {
            tupleString = tupleString.concat(' skills LIKE ','"%',pc.value,'%"',' OR');
          }
          tupleString = tupleString.substring(0,tupleString.length - 3);
          selectedFilters[tag] = tupleString
        }
        else {
          let tupleString = '('
          for (const pc of filtersObject[tag]) {
            tupleString = tupleString.concat('"',pc.value,'"',',');
          }
          tupleString = tupleString.substring(0,tupleString.length - 1);
          tupleString = tupleString.concat(")")
          selectedFilters[tag] = tupleString
        }
      }
    }
    console.log(JSON.stringify(selectedFilters))
    fetch(`/api/databricks/applyFilter`, {
      method: 'POST',
      headers: { 'Accept': 'application/json',
                "Content-Type": "application/json"},
      body: JSON.stringify(selectedFilters)})
      .then(response => response.json())
      .then(function(data) { 
        props.setJ(data)
        if (data.length === 0) {
          props.timeE(true);
        }
        else {
          props.timeE(false);
        }
       })
      .catch(error => console.error('Error fetching data:', error));
    }

  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  return (
    <div className={sidebarClass}>
      <h1>Filtri</h1>
      <div className="filter-side">
        <CreatableSelect 
        isClearable 
        options={idJob}
        isMulti
        onChange={handleTypeSelectIdJob}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Job ID"
        />
      </div>
      <div className="filter-side">
        <Select options={jobData} 
        isMulti
        onChange={handleTypeSelectJob}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Categoria Professionale"
        />
      </div>
      <div className="filter-side">
        <Select options={regData} 
        isMulti
        onChange={handleTypeSelectReg}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Region"
        />
      </div>
      <div className="filter-side">
        <Select options={opsData} 
        isMulti
        onChange={handleTypeSelectOps}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Operation"
        />
      </div>
      <div className="filter-side">
        <Select options={CDCData} 
        isMulti
        onChange={handleTypeSelectCDC}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona CDC"
        />
      </div>
      <div className="filter-side">
        <Select options={branchData} 
        isMulti
        onChange={handleTypeSelectBranch}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Città"
        />
      </div>
      <div className="filter-side">
        <Select options={blData} 
        isMulti
        onChange={handleTypeSelectBl}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Tipo di Contratto"
        />
      </div>
      <div className="filter-side">
        <Select options={clientData} 
        isMulti
        onChange={handleTypeSelectClient}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Cliente"
        />
      </div>
      <div className="filter-side">
        <Select options={skillData} 
        isMulti
        onChange={handleTypeSelectSkill}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Skill"
        />
      </div>
      <button 
      onClick={sendBack}
      className="cta-red">
        {"Visualizza le opportunità"}
      </button>
      <button onClick={props.toggleSidebar} className="sidebar-toggle">
        FILTRI
      </button>
    </div>
  );
};
export default Sidebar;