import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import "./Job.css"
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { useIsDirectResult } from "../../../context/isDirectMatchingResult";
import TableMatches from "../Match/Match";
import LoaderComp from "../../../loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import { format } from "date-fns";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import { useIsMatchingRealTime } from "../../../context/isMatchingRealTime";
import { useMsal } from "@azure/msal-react";
import { useIsInfoPage } from "../../../context/isInfoPage";
import InfoPage from "../../Info/InfoPage";
import { useInfoString } from "../../../context/infoString";

function TableJobs() {
  const [jobs, setJobs] = useState([])
  const [announcementId, setOffer] = useState("");
  const [row, setRow] = useState([]);
  const [headers, setHeaders] = useState({});
  const [codFisc, setCodFisc] = useState("");
  const { isDirectResult, toggleView } = useIsDirectResult();
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [userId] = useState(process.env.REACT_APP_DEFAULT_USER);
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const { isMatchingRealTime, toggleViewReal } = useIsMatchingRealTime();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkList, setCheckList] = useState<any[]>([])
  const [done, setDone] = useState<any[]>([])
  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
  const { infoString, setInfoString } = useInfoString();
  const [isDetails, setDetails] = useState(false);
  const [iqg, setIqg] = useState(false);
  const [email, setEmail] = useState(false);
  const [emailC, setEmailC] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailsC, setEmailsC] = useState([]);
  const [questions, setQuestions] = useState([])

  const { accounts } = useMsal();

  const [matches, setMatches] = useState([])
  const [explain, setExplain] = useState<any>({})
  const [headersNew, setHeadersNew] = useState<any>({})

  const [anagrafiche, setAnagrafiche] = useState([])
  const [interviste, setInterviste] = useState([])
  const [ordini, setOrdini] = useState([])
  const [anagrafica, setAnagrafica] = useState(false);
  const [ordine, setOrdine] = useState(false);
  const [intervista, setIntervista] = useState(false);
  const [cv, setCv] = useState(false);
  const [timeAnagrafica, setTimeAnagrafica] = useState(false)
  const [timeOrdine, setTimeOrdine] = useState(false)
  const [timeCvs, setTimeCvs] = useState(false)
  const [timeIntervista, setTimeIntervista] = useState(false)
  const [cvs, setCvs] = useState("")
  const [urlPDF, setUrlPDF] = useState("");
  const [isAI, setIsAI] = useState(false)

  const [isPenalized, setIsPenalized] = useState<any>(1)
  const [isOrderLastActivity, setIsOrderLastActivity] = useState<any>(1)


  const [activeContent, setActiveContent] = useState(
    isMatchingRealTime ? "realtime" : "normal"
  );

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const switchContent = () => {
    setActiveContent((prevContent) =>
      prevContent === "normal" ? "realtime" : "normal"
    );
    toggleViewReal();
    setIsDropdownOpen(false);
  };

  const setResult = (announcement_id: any, row:any) => {
    setOffer(announcement_id)
    setRow(row);
    setHeaders(Object.keys(row))
    toggleView(false);
  }
  interface Job {
    announcement_id: string;
    announcement_type: string;
    business_line: string;
    offer_number: string;
    work_order_number: string;
    branch: string;
    reception_date: Date;
    contract_beginning_date: Date;
    contract_duration: number;
    professional_category: string;
    job_owner: string;
    client_desc: String;
    job_title: string;
    work_city: string;
    skills: string;
    educations: string;
    languages: string;
    cards: string;
    work_province: string;
    url: string;
    Categoria_protetta: string;
  }

  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () => [
      {
        accessorKey: 'professional_category',
        header: 'Categoria professionale',
        size: 150
      },
      {
        accessorKey: 'job_title',
        header: 'Job title',
        size: 150
      },
      {
        accessorKey: 'client_desc',
        header: 'Cliente',
        size: 150
      },
      {
        accessorKey: 'announcement_id',
        header: 'Job ID',
        size: 100
      },
      {
        accessorKey: 'business_line',
        header: 'Tipo di Contratto',
        size: 25
      },
      {
        accessorKey: 'announcement_type',
        header: 'Tipologia',
        size: 150
      },
      {
        accessorKey: 'work_city',
        header: 'Città di lavoro',
        size: 150
      },
      {
        accessorKey: 'branch',
        header: 'CDC',
        size: 25
      },
      {
        accessorKey: 'job_owner',
        header: 'Job Owner',
        size: 150
      },
      {
        accessorKey: 'reception_date',
        header: 'Data ricezione/pubblicazione',
        Cell: ({ row }) => format(new Date(row.original.reception_date), "yyyy-MM-dd"),
        size: 25
      },
      {
        accessorKey: 'skills',
        header: 'Skill richieste',
        size: 25
      },
      {
        accessorKey: 'educations',
        header: 'Education richiesta',
        size: 150
      },
      {
        accessorKey: 'languages',
        header: 'Lingue richieste',
        size: 150
      },
      {
        accessorKey: 'cards',
        header: 'Patenti richieste',
        size: 150
      },
      {
        accessorKey: 'offer_number',
        header: 'Numero Offerta',
        size: 25
      },
      {
        accessorKey: 'work_order_number',
        header: 'Numero Ordine',
        size: 25
      },
      {
        accessorKey: 'contract_beginning_date',
        header: 'Data inizio contratto',
        Cell: ({ row }) => format(new Date(row.original.contract_beginning_date), "yyyy-MM-dd"),
        size: 100
      },
      {
        accessorKey: 'contract_duration',
        header: 'Durata contratto',
        size: 100
      },
      {
        accessorKey: 'work_province',
        header: 'Provincia di lavoro',
        size: 150
      },
      {
        accessorKey: 'url',
        header: 'Link pagina offerta',
        Cell: ({ row }) => <a href={row.original.url}>{row.original.url}</a>,
        size: 150
      },
      {
        accessorKey: 'Categoria_protetta',
        header: 'Categoria protetta',
        size: 150
      },
    ],
    []
  )

  useEffect(() => {
    toggleView(true);
    setInfoString(
      <div>
        <span style={{ color: "red", fontWeight:"bold" }}><h2>Opportunità aperte</h2></span>
        <p><h3>Benvenuto nella home page del GPT Talent Finder.
                In questa sezione puoi utilizzare l’algoritmo di matching per trovare i migliori candidati per una posizione lavorativa.
                Nella tabella principale trovi tutte le posizioni lavorative aperte ed inserite a sistema entro la mezzanotte del giorno precedente. Utilizzando i filtri presenti nel menù a scomparsa sulla sinistra puoi cercare la posizione lavorativa di tuo interesse. Inoltre, nell’angolo in alto a sinistra della tabella puoi trovare alcune funzionalità che ti permetteranno di filtrare i dati per una specifica colonna, nascondere le colonne che non ti interessa visualizzare e modificare la visualizzazione della tabella stessa.
                Una volta individuata la posizione lavorativa di interesse, clicca sul tasto “Matching” per visualizzare i candidati più affini. I risultati mostreranno i candidati in un intorno (in linea d’aria) di 25km dal luogo di lavoro.
                Se, invece, la posizione lavorativa non è presente a sistema clicca sul bottone “Opportunità aperte” per passare alla pagina “Definisci l’offerta di lavoro”
                </h3></p>
      </div>
    )
    fetch(`/api/databricks/jobread?username=${accounts[0]['username']}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
      .then(response => response.json())
      .then(data => setJobs(data))
      .catch(error => console.error('Error fetching data:', error));

    fetch(`/api/realtime/testDistance`, {
        method: 'GET',
        headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
        .then(response => console.log(response.json()))
        .catch(error => console.error('Error fetching data:', error));
  }, [])

  const data: Job[] = jobs

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    initialState: { density: 'compact' },
    enableStickyHeader: true,
    localization: {'actions':'Suggerimento'},
    muiTableContainerProps: {sx: { maxHeight: '500px' } },
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <div>
      <button type="submit" className="button-action-dir" onClick={() => setResult(row.original.announcement_id,row.original)}>
        <h4>MATCHING</h4>
      </button>
      </div>

  ),
  });

  return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
      <div>
      {isDirectResult ?
      <div style={{textAlign:"center"}}>
        <div>
            <div id="menu-toggle-mobile">
              <div
                className={`container-toggle-dropdown mobile ${
                  isDropdownOpen ? "active" : ""
                }`}
                onClick={toggleDropdown}
              >
                <div>
                  {activeContent === "realtime" ? (
                    <>
                      <h4 className="m0">Opportunità aperte</h4>
                      <p className="m0">Cerca tra le opportunità disponibili</p>
                    </>
                  ) : (
                    <>
                      <h4 className="m0">Definisci l’offerta di lavoro</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </>
                  )}
                </div>
                <img
                  width={12}
                  src={isDropdownOpen ? chevronUp : chevronDown}
                  alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
                  className="chevron-icon"
                />
              </div>
              {isDropdownOpen && (
                <div className="dropdown-menu-mobile">
                  {activeContent === "normal" ? (
                    <div onClick={switchContent}>
                      <h4 className="m0">Opportunità aperte</h4>
                      <p className="m0">Cerca tra le opportunità disponibili</p>
                    </div>
                  ) : (
                    <div onClick={switchContent}>
                      <h4 className="m0">Definisci l’offerta di lavoro</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          <div>
        </div>
          {(jobs.length > 0 || timeElapsed) ? 
            <div>
              <Sidebar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} setJ={setJobs} jobs={jobs} timeE={setTimeElapsed} userId={userId} />
              <div className="back">
                <MaterialReactTable 
                table={table}
                />
              </div>
              <div className="spacing-div">
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
            </div> : <div className="div-center">
            <div>
              <h3> Caricamento Offerte</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
        </div>}
        </div>
      </div > 
      : 
      <div>
        <TableMatches announcement_id={announcementId} row={row} headers={headers} 
        userId={userId} checkList={checkList} 
        setJ = {setJobs}
        setCheckList={setCheckList} done={done} setDone={setDone} 
        matches={matches} setMatches={setMatches} explain={explain} 
        setExplain={setExplain} isDetails={isDetails} iqg={iqg} email={email} emailC={emailC}
        setDetails={setDetails} setIqg={setIqg} setEmail={setEmail} setEmailC={setEmailC}
        emails={emails} setEmails={setEmails} emailsC={emailsC} setEmailsC={setEmailsC}
        questions={questions} setQuestions={setQuestions}
        headersNew={headersNew} setHeadersNew={setHeadersNew}
        anagrafiche={anagrafiche}
        setAnagrafiche={setAnagrafiche}
        interviste={interviste}
        setInterviste={setInterviste}
        ordini={ordini}
        setOrdini={setOrdini}
        anagrafica={anagrafica}
        setAnagrafica={setAnagrafica}
        ordine={ordine}
        setOrdine={setOrdine}
        intervista={intervista}
        setIntervista={setIntervista}
        cv={cv}
        setCv={setCv}
        codFisc={codFisc}
        setCodFisc={setCodFisc}
        timeAnagrafica={timeAnagrafica}
        setTimeAnagrafica={setTimeAnagrafica}
        timeOrdine={timeOrdine}
        setTimeOrdine={setTimeOrdine}
        timeIntervista={timeIntervista}
        setTimeIntervista={setTimeIntervista}
        timeCvs={timeCvs}
        setTimeCvs={setTimeCvs}
        cvs={cvs}
        setCvs={setCvs}
        urlPDF={urlPDF}
        setUrlPDF={setUrlPDF}
        isAI={isAI}
        setIsAI={setIsAI}
        isPenalized={isPenalized}
        setIsPenalized={setIsPenalized}
        isOrderLastActivity={isOrderLastActivity}
        setIsOrderLastActivity={setIsOrderLastActivity}>
        </TableMatches>
      </div>
      }
      </div>
      }
    </div>
  )

}

export default TableJobs;
