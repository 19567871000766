import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import LoaderComp from "../../../loader/Loader";
import EmailToCandidate from "../Match/Email/EmailJob";
import EmailClient from "../../DirectMatching/Match/Email/Email";
import IQG from "../../DirectMatching/Match/IQG/IQG";
import CardDetailsJobRev from "./CardDetails/CardDetailsJobRev";
import { useMsal } from "@azure/msal-react";
import { useIsInfoPage } from "../../../context/isInfoPage";
import { useInfoString } from "../../../context/infoString";
import InfoPage from "../../Info/InfoPage";


function RealTimeRevMatch(props: any) {
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [jobID, setJobID] = useState("");
  const [matchDetails, setMatchDetails] = useState<any>({});
  const [jobDetails, setJobDetails] = useState({});
  const [dropdown, setDropdown] = useState(null);

  const { accounts } = useMsal();
  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
  const { infoString, setInfoString } = useInfoString();

  const findJob = async () => {
        let headersTemp:any = {}
        let expTemp:any = {}
        props.headers.forEach((value:any) => {
          if (value === 'jobTitle') {
    
            headersTemp[value] = 'Esperienza / Interesse candidato'
          }
    
          else if (value === 'city') {
            headersTemp[value] = 'Città candidato'
          }
    
          else if (value === 'maxRadius') {
            headersTemp[value] ='Distanza massima'
          }
    
          else if (value === 'skills') {
            headersTemp[value] ='Skill'
          }
    
          else if (value === 'education') {
            headersTemp[value] ='Education'
          }
    
          else if (value === 'language') {
            headersTemp[value] ='Lingue'
          }
    
          else if (value === 'license') {
            headersTemp[value] ='Patenti'
          }

          else if (value === 'interest') {
                headersTemp[value] ='Interesse candidato'
              }

          else if (value === 'Categoria_protetta_filtro') {
            headersTemp[value] ='Per Categoria Protetta'
          }
        })
        props.setHeadersNew(headersTemp)
        fetch(`/api/realtime/embeddingsearchreverse?username=${accounts[0]['username']}`, {
          method: 'POST',
          headers: { 'Accept': 'application/json',
                    "Content-Type": "application/json"},
          body: JSON.stringify(props.dataSearch)})
          .then(response => response.json())
          .then(function(data) {
            props.setMatches(data);
            if (data.length === 0) {
              setTimeElapsed(true);
            }
            for (let i=0; i<=Math.min(4,data.length-1); i++) { 
              fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                method: 'POST',
                headers: { 'Accept': 'application/json',
                          "Content-Type": "application/json"},
                body: JSON.stringify({cv:"",jobDescription:"",motivo_match:data[i].Motivo_Match})})
                .then(response => response.json())
                .then(function(explain) {
                  expTemp[data[i].offer_id] = explain['spiegazione']
                  props.setExplain(expTemp)
                })
            }
          })      
    }


  const getBack = () => {
    setInfoString(<div>
      <span style={{ color: "red", fontWeight:"bold" }}><h2>Definisci le caratteristiche del candidato</h2></span>
      <p><h3>In questa sezione puoi definire tu le caratteristiche del candidato qualora non fosse già inserito a sistema (per esempio, nel caso si sia appena presentato in filiale per registrarsi).
              Usa i menu a tendina per definire le caratteristiche del candidato. I campi mandatori sono quelli per cui è presente l’asterisco, anche se è sufficiente inserire uno tra Esperienza candidato o Interesse candidato oltre che la città. Per l’Esperienza del candidato, puoi scegliere fra quelle presenti a sistema (derivati dalle ontologie standard Adecco) oppure puoi inserire un valore diverso che si avvicina maggiormente al ruolo per cui il candidato ha esperienza. In questo caso, la ricerca semantica ti suggerirà le posizioni lavorative aperte più affini al ruolo selezionato.
              Inoltre, usa la barra in basso per definire la distanza massima (in linea d’aria) entro cui cercare le posizioni lavorative.
              Clicca sul pulsante “Trova le offerte in linea” per visualizzare i risultati della ricerca.
              </h3></p>
      </div>)
        props.setMatches([])
        props.setIsMatchingRealRevResult(false);
  }

  const generateQuestion = (match:any) => {
    console.log(match["professional_category"])
    setMatchDetails(match);
    props.setIqg(true);
  }

  const getDetails = (match:any) => {
    setJobID(match['offer_id']);
    setMatchDetails(match);
    props.setDetails(true);
  }

  const toggleDropdown = async(id:any, match:any) => {
    setDropdown((prev) => {
      if (prev === id) {
        return null
      }
      else {
        return id
      }
    });
    let expTemp:any = props.explain
    if (!(match.offer_id in props.explain)) {
     fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                method: 'POST',
                headers: { 'Accept': 'application/json',
                          "Content-Type": "application/json"},
                body: JSON.stringify({cv:"",jobDescription:"",motivo_match:match.Motivo_Match})})
                .then(response => response.json())
                .then(function(explain) {
                  expTemp[match.offer_id] = explain['spiegazione']
                  props.setExplain(expTemp)
                })
          } 
  };

  const toggleCheck = (id:any) => {
    let partial = props.checkList;
    if (props.checkList.includes(id)) {
      partial = partial.filter(function(e:any) { return e !== id })
      props.setCheckList(partial)
      
    }
    else {
      props.setCheckList([...props.checkList,id])
    }

    console.log(props.checkList)
  };

  const backDetails = () => {
    props.setDetails(false);
  }

  const generateEmail = (match: any) => {
        setMatchDetails({
        'professional_category':match['professional_category'],
        'Motivo_Match':match['Motivo_Match'],
        'Distanza_km':match['distance'],
        'work_city':match['workcity'],
        });
    setJobID(match.offer_id)
    props.setEmailC(true);
  }

  const generateEmailToJob = (match:any) => {
    setMatchDetails(match);
    setJobDetails({'Motivo_Match':match['Motivo_Match'],'Distanza_km':match['distance']});
    setJobID(match.offer_id)
    props.setEmail(true);
  }


  useEffect(() => {
    setInfoString(<div>
      <span style={{ color: "red", fontWeight:"bold" }}><h2>Risultati ricerca Talent Placer</h2></span>
      <p><h3>In questa pagina trovi i risultati della ricerca effettuata. 
              Sulla sinistra trovi un riassunto delle caratteristiche del candidato. 
              Le schede, invece, mostrano i risultati (posizioni lavoratice) ordinati per score decrescente (quindi dal più affine al meno affine). Ogni scheda della posizione lavorativa riporta alcune informazioni rilevanti come: 
            <ul>
              <li>ID Offerta (composta da ORD/OFF in base al fatto si parli di offerte pubblicate o ordini interni, numero ordine / offerta, CDC)</li>
              <li>Categoria professionale della posizione lavorativa</li>
              <li>Job title della posizione lavorativa</li>
              <li>Cliente</li>
              <li>Distanza domicilio – luogo di lavoro</li>
            </ul>
            Inoltre, è presente un riquadro che permettere di “spuntare” le posizioni lavorative già visualizzate. Il flag rimane attivo per l’intera sessione.
            In basso ad ogni scheda, sono presenti 4 bottoni:
            <ul>
              <li>Dettagli, che reindirizza ad una pagina di dettaglio contenente le informazioni della posizione lavorativa, gli step della pipeline in cui è stato inserito il candidato da Gennaio 2023 ad oggi, le interviste effettuate dai recruiter da Gennaio 2023 ad oggi ed il CV</li>
              <li>Email Candidato, che permette di creare una mail di presentazione della posizione lavorativa al candidato</li>
              <li>Genera Domande, che permette di generare le domande per il colloquio con il candidato per la posizione lavorativa</li>
              <li>Email cliente, permette di generare la mail per presentare il candidato al cliente per la posizione lavorativa specifica</li>
            </ul>
          </h3>
        </p>
    </div>)
    if (props.matches.length === 0) {
      findJob();
    }
  }, [])


  return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
    <div>
      {(props.email)?
        <EmailClient email={props.email} setEmail={props.setEmail} candidate={jobDetails} candidateId={"realtime"} jobId={jobID} category={matchDetails["professional_category"]} workCity={props.dataSearch["city"]} emailsC={props.emails} setEmailsC={props.setEmails}></EmailClient>
      :
      <div>
      {(props.emailC) ?
      <div>
        <EmailToCandidate email={props.emailC} setEmail={props.setEmailC} job={matchDetails} candidateId={"realtime"} jobId={jobID} emails={props.emails} setEmails={props.setEmails}></EmailToCandidate>
      </div>
      :
      <div>
        {(props.isDetails) 
        ?
          <div>
            <div>
              <CardDetailsJobRev 
              jobID={jobID} 
              job={matchDetails} 
              setIsMatchingRealRevResult={props.setIsMatchingRealRevResult} 
              setDetails={props.setDetails}
              jobInfo={props.jobInfo}
              setJobInfo={props.setJobInfo}
              jobInfos={props.jobInfos}
              setJobInfos={props.setJobInfos}
              timeJobInfo={props.timeJobInfo}
              setTimeJobInfo={props.setTimeJobInfo}
              ></CardDetailsJobRev>
            </div>
          </div>
        : 
        <div>
        {(props.iqg) ?
          <div>
            <IQG category={matchDetails["professional_category"]} iqg={props.iqg} setIqg={props.setIqg} candidateId={"realtime"} jobId={jobID} questions={props.questions} setQuestions={props.setQuestions}></IQG>    
          </div>
          :
        <div>
          <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
            </div>
          </div>
          {(props.matches.length > 0 || timeElapsed)? 
            <div className="row-main">
              <div className="column-table-direct">
                {props.headers.filter((item:any) => {
                let toExclude = [
                ]
                if (item === 'jobTitle' || item === 'interest') {
                  if (props.dataSearch[item] === null) {
                    toExclude.push(item)
                  }
                }
                else {
                  if (props.dataSearch[item].length == 0) {
                    toExclude.push(item)
                  }
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
                <div className="inline-div-super-dir">
                    <h2 className="test-h3">{props.headersNew[header]}</h2>
                    <h5>{props.dataSearch[header]}</h5>
                </div>
                ))}
              </div>
              <div className="sovra-container-direct">
              {(timeElapsed &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                  {props.matches.map((match: any) => (
                    <div key={match.offer_id} className="candidate-card-dir">
                      <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(match.offer_id)} checked={props.checkList.includes(match.offer_id)}></input>
                      <label className="label-for-check">Visualizzato</label>
                      <div className="sub-container-lr">
                        <div className="section-infos-right-dir">
                          <p><strong>ID Offerta: </strong>{match.offer_id}</p>
                          <p><strong>Cat. Prof.: </strong>{match.professional_category}</p>
                          <p><strong>Job Title: </strong>{match.job_title}</p>
                          {(match.client_desc && <p><strong>Cliente: </strong>{match.client_desc}</p>)}
                          <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {props.dataSearch['city']} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {match.workcity} </strong>pari a {Math.round(Number(match.distance))} KM</p>
                        </div>
                        <div className="section-infos-left">
                      <p
                            className= {
                              match.announcement_type === "OFFER" 
                                                        ? "blue-tag" 
                                                        : match.announcement_type === "ORDER"
                                                        ? "orange-tag"
                                                        : ""
                            }
                            >
                            {match.announcement_type}
                          </p>
                      </div>
                      </div>
                      <div id="dropdown-full">
                        <div 
                          key={match.offer_id}
                          className={`dropdown-slot ${dropdown === match.offer_id ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(match.offer_id,match)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === match.offer_id ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === match.offer_id ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === match.offer_id ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                              {dropdown === match.offer_id? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === match.offer_id ? '' : 'match-reason-hidden'}`}>
                        <span className={`match-reason ${dropdown === match.offer_id ? '' : 'match-reason-hidden'}`}>
                            {(props.explain[match.offer_id] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                        </span>
                          {dropdown === match.offer_id? props.explain[match.offer_id]: ""}
                        </div>
                        <div className="row-button">
                          <button className="cta-red-mat" onClick={() => getDetails(match)}>
                            {"Dettagli"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmail(match)}>
                            {"Email Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateQuestion(match)}>
                            {"Genera Domande"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmailToJob(match)}>
                            {"Email Cliente"}
                          </button>
                        </div>
                      </div>
                  </div>
                ))}
              </div>
            </div>
          : 
          <div className="div-center">
            <div>
              <h3>Ricerca posizioni lavorative in corso</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
          </div>}
          </div>
          }
          </div>
          }
          </div>
          }
          </div>
          }
          <div style={{textAlign:"center"}}>
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
            </div>
        </div>
        }
      </div>
  )

}

export default RealTimeRevMatch;