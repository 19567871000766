import { useEffect, useState } from "react"
import { _get } from "../../../../../apiClient";
import '../CardDetails/Buttons/CV.css'
import LoaderComp from "../../../../loader/Loader";
import { Buffer } from "buffer";
import { useCVPageContext } from "../../../../context/cvPageContext";
import { useLocation } from "react-router-dom";




function CVPage(props:any) {
    const [timeE, setTimeE] = useState(false)
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const contextValue = params.get('context');
    const{codFiscP,
          setCodFiscP,
          isAIP,
          setIsAIP,
          timeCvsP,
          setTimeCvsP,
          urlPDFP,
          setUrlPDFP
        } = useCVPageContext();
    
    const getCVS = async (contextValue:any) => {
      fetch(`/api/realtime/getCVs?associate_id=${contextValue}&userId=PIT`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(data) {
          setTimeCvsP(true)
          if (typeof data["data"] == "string" && data["data"] !=="vuoto") {
            setUrlPDFP(data["data"]);
            setIsAIP(true);
          }

          else if (data["data"] === "vuoto") {
            setUrlPDFP("");
            setTimeE(true);
          }

          else {
            const buffer = Buffer.from(data["data"]);
            const base64String = buffer.toString('base64');  
            setUrlPDFP(base64String);
          }
        } )
        .catch(error => console.error('Error fetching data:', error));
    }

      useEffect(() => {
        if (urlPDFP === "" && !timeCvsP) {
          getCVS(contextValue);
        }
      }, [])

      return (
        <div>
          {(urlPDFP != "") ?
            <div className="spacing">
              {(isAIP) ? <pre>
                  <p className="format-p">{urlPDFP}</p>
              </pre>
              :
              <div className="pdf-div">
                <iframe className="format-frame" src={`data:application/pdf;base64,${urlPDFP}#toolbar=0`}/>
              </div>
              }
            </div>
            :
            <div>
              {(timeCvsP) ?
              <div className="div-center">
              <div style={{marginTop:"2%"}}>
                  <h3>Nessun CV Trovato</h3>
              </div>
              </div>
            :
            <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Scaricamento CV...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
            </div>
            }
          </div>
            }
        </div> 
      )
}

export default CVPage

