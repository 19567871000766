import "./SidebarBatch.css"
import {useState} from 'react'
import Select from 'react-select'
import usePopulateField from "../../../../hooks/usePopulateFields";
import { _post } from "../../../../../apiClient";
import CreatableSelect from "react-select/creatable";
import { useMsal } from "@azure/msal-react";

const SidebarBatch = (props:any) => {
  const [candidateStateData, setCandidateStateData] = useState<string[]>([]);
  const [candidateAge, setCandidateAge] = useState<string[]>([]);
  const [cpData, setCPData] = useState<string[]>([]);
  const [daData, setDAData] = useState<string[]>([]);
  const [selectedOptionCP, setSelectedOptionCP] = useState<[]>([]);
  const [selectedOptionDA, setSelectedOptionDA] = useState<[]>([]);
  const [selectedOptionCandidateState, setSelectedOptionCandidateState] = useState<[]>([]);
  const [selectedOptionCandidateAge, setSelectedOptionCandidateAge] = useState<[]>([]);

  const { accounts } = useMsal();

  usePopulateField("/api/databricks/filterCandidateState", setCandidateStateData);
  usePopulateField("/api/databricks/filterCandidateAge", setCandidateAge);
  usePopulateField("/api/databricks/filterCP", setCPData);
  usePopulateField("/api/databricks/filterDA", setDAData);

  let filtersObject: Record<string, any> = {'announcement_id': props.jobId,'Classe_Eta':selectedOptionCandidateAge,
                                            'Categoria_protetta_filtro':selectedOptionCP,'Dote_attiva_filtro':selectedOptionDA, 'candidate_state':selectedOptionCandidateState};
  


  const handleTypeSelectCandidateAge = (e:any) => {
    setSelectedOptionCandidateAge(e)
  };

  const handleTypeSelectCP = (e:any) => {
    setSelectedOptionCP(e);
  };

  const handleTypeSelectDA = (e:any) => {
    setSelectedOptionDA(e);
  };

  const handleTypeSelectCandidateState = (e:any) => {
    setSelectedOptionCandidateState(e);
  };

  const sendBack = async (s:any) => {
    props.setMatches([])
    let selectedFilters: any = {'isFiltered':true,'announcement_id':"",'Classe_Eta':"",'Categoria_protetta_filtro':"","Dote_attiva_filtro":"",'candidate_state':""}
    props.timeE(false);
    for (const tag in selectedFilters) {
      if (tag === 'isFiltered') continue;
      else if (tag === 'candidate_state' && filtersObject[tag].length !== 0) {
        let tupleString = '('
        for (const pc of filtersObject[tag]) {
          let tempValue = ""
          if (pc.value === 'Assunto R&S') {
            tempValue = "A"
          }
          else if (pc.value === 'Certificato') {
            tempValue = "I "
          }
          else if (pc.value === 'Candidato Non Idoneo') {
            tempValue = "NI"
          }
          else if (pc.value === 'Staff Interno') {
            tempValue = "SI"
          }
          else if (pc.value === 'Da Valutare') {
            tempValue = 'DV'
          }
          else {
            tempValue = "NA"
          }
          tupleString = tupleString.concat('"',tempValue,'"',",");
        }
        tupleString = tupleString.substring(0,tupleString.length - 1);
        tupleString = tupleString.concat(")")
        selectedFilters[tag] = tupleString
      }
      if (tag !== 'candidate_state' && filtersObject[tag].length !== 0) {
        let tupleString = '('
        for (const pc of filtersObject[tag]) {
          tupleString = tupleString.concat('"',pc.value,'"',',');
        }
        tupleString = tupleString.substring(0,tupleString.length - 1);
        tupleString = tupleString.concat(")")
        selectedFilters[tag] = tupleString
      }
    }

    props.findCandidate(props.jobId,props.isPenalized,{},`announcement_id=${props.jobId}&isPenalized=${props.isPenalized}&isOrderLastActivity=${props.isOrderLastActivity}&isFiltered=1&Classe_Eta=${selectedFilters['Classe_Eta']}&candidate_state=${selectedFilters['candidate_state']}&Dote_attiva_filtro=${selectedFilters['Dote_attiva_filtro']}&Categoria_protetta_filtro=${selectedFilters['Categoria_protetta_filtro']}`)

    }

  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  return (
    <div className={sidebarClass}>
      <h1>Filtri</h1>
      <div className="filter-side">
        <Select options={candidateAge} 
        isMulti
        onChange={handleTypeSelectCandidateAge}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Classe Età"
        />
      </div>
      <div className="filter-side">
        <Select options={daData} 
        isMulti
        onChange={handleTypeSelectDA}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Dote Attiva?"
        />
      </div>
      <div className="filter-side">
        <Select options={cpData} 
        isMulti
        onChange={handleTypeSelectCP}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Categoria Protetta?"
        />
      </div>
      <div className="filter-side">
        <Select options={candidateStateData} 
        isMulti
        onChange={handleTypeSelectCandidateState}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Stato Candidato"
        />
      </div>
      <button 
      onClick={sendBack}
      className="cta-red">
        {"Visualizza le opportunità"}
      </button>
      <button onClick={props.toggleSidebar} className="sidebar-toggle">
        FILTRI
      </button>
    </div>
  );
};
export default SidebarBatch;