import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { _get } from '../../../../../../apiClient';
import LoaderComp from '../../../../../loader/Loader';
import { format } from 'date-fns';

function Interviste (props:any) {
    const [timeE, setTimeE] = useState(false)

    const getInt = async () => {
      fetch(`/api/databricks/interviste?associate_id=${props.codFisc}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(data) {
          if (data.length === 0) {
            setTimeE(true);
          }
          props.setTimeIntervista(true)
          props.setInterviste(data)
        })
      }

      useEffect(() => {
        if (props.interviste.length === 0 && !props.timeIntervista) {
          getInt();
        }
      }, [])

    interface Interviste {
        branch_desc: string;
        interview_date: Date;
        interviewer_name: string;
        verbal_capacity: string;
        observations: string;
        bearing: string;
        attitude_observations: string;
        appearance_look: string;
      }
    
      const columns = useMemo<MRT_ColumnDef<Interviste>[]>(
        () => [
          {
            accessorKey: 'branch_desc',
            header: 'Filiale',
            size: 100
          },
          {
            accessorKey: 'interview_date',
            header: 'Data intervista',
            Cell: ({ row }) => format(new Date(row.original.interview_date), "yyyy-MM-dd"),
            size: 25
          },
          {
            accessorKey: 'interviewer_name',
            header: 'Intervistatore',
            size: 150
          },
          {
            accessorKey: 'verbal_capacity',
            header: 'Capacità verbali',
            size: 25
          },
          {
            accessorKey: 'observations',
            header: 'Osservazioni',
            size: 100
          },
          {
            accessorKey: 'bearing',
            header: 'Portamento',
            size: 25
          },
          {
            accessorKey: 'attitude_observations',
            header: 'Atteggiamento',
            size: 25
          },
          {
            accessorKey: 'appearance_look',
            header: 'Aspetto',
            size: 100
          },
        ],
        []
      )
        const data: Interviste[] = props.interviste

        const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        muiTableContainerProps: {sx: { maxHeight: '500px' } },

    });

    return (
      <div>
        {props.interviste.length || props.timeIntervista?
        <div style={{marginTop:"2%",marginBottom:"15%"}}>
            <MaterialReactTable table={table}></MaterialReactTable>
        </div>
        :
        <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Caricamento interviste...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
      }
      </div>
    )
}

export default Interviste