import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";

interface InfoStringContextType {
  infoString: any;
  setInfoString: Dispatch<SetStateAction<any>>
}

const InfoStringContext = createContext<
InfoStringContextType | undefined
>(undefined);

export const useInfoString = () => {
  const context = useContext(InfoStringContext);
  if (!context) {
    throw new Error(
      "Put in provider",
    );
  }
  return context;
};

export const InfoStringProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [infoString, setInfoString] = useState(
    <h3>Questa è la info di default</h3>
  );
  return (
    <InfoStringContext.Provider value={{ infoString, setInfoString}}>
      {children}
    </InfoStringContext.Provider>
  );
};
