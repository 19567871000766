import "./SidebarRev.css"
import {useState} from 'react'
import Select from 'react-select'
import usePopulateField from "../../../hooks/usePopulateFields";
import CreatableSelect from 'react-select/creatable';
import { _post } from "../../../../apiClient";
 
const SidebarRev = (props:any) => {
  const [candidateData, setCandidateData] = useState<string[]>([]);
  const [cpData, setCPData] = useState<string[]>([]);
  const [daData, setDAData] = useState<string[]>([]);
  const [nameData, setNameData] = useState<string[]>([]);
  const [branchData, setBranchData] = useState<string[]>([]);
  const [candidateAge, setCandidateAge] = useState<string[]>([]);
  const [candidateState, setCandidateState] = useState<string[]>([]);
  const [candidateCollaborator, setCandidateCollaborator] = useState<string[]>([]);
  const [selectedOptionCandidate, setSelectedOptionCandidate] = useState<[]>([]);
  const [selectedOptionCP, setSelectedOptionCP] = useState<[]>([]);
  const [selectedOptionDA, setSelectedOptionDA] = useState<[]>([]);
  const [selectedOptionName, setSelectedOptionName] = useState<[]>([]);
  const [selectedOptionCandidateCity, setSelectedOptionCandidateCity] = useState<[]>([]);
  const [selectedOptionCandidateAge, setSelectedOptionCandidateAge] = useState<[]>([]);
  const [selectedOptionCandidateCollaborator, setSelectedOptionCandidateCollaborator] = useState<[]>([]);
  const [selectedOptionCandidateState, setSelectedOptionCandidateState] = useState<[]>([]);
  const [selectedOptionCollaboratorSL, setSelectedOptionCollaboratorSL] = useState<[]>([]);
 
  usePopulateField("/api/databricks/filterCandidate", setCandidateData);
  usePopulateField("/api/databricks/filterCP", setCPData);
  usePopulateField("/api/databricks/filterDA", setDAData);
  usePopulateField("/api/databricks/filterName", setNameData);
  usePopulateField("/api/databricks/filterCandidateCity", setBranchData);
  usePopulateField("/api/databricks/filterCandidateAge", setCandidateAge);
  usePopulateField("/api/databricks/filterCandidateCollaboratorStatus", setCandidateCollaborator);
  usePopulateField("/api/databricks/filterCandidateState", setCandidateState);

  const optionsSL = [
    { value: "1", label: 'Sì' },
    { value: "0", label: 'No' },
  ]
 
  let filtersObject: Record<string, any> = {'candidate':selectedOptionCandidate,
    'home_city':selectedOptionCandidateCity, 'Classe_Eta': selectedOptionCandidateAge,
    'type_collaborator_status': selectedOptionCandidateCollaborator,
    'candidate_state': selectedOptionCandidateState,
    'sl_bench':selectedOptionCollaboratorSL,
    'name':selectedOptionName,
    'Categoria_protetta_filtro':selectedOptionCP,
    'Dote_attiva_filtro':selectedOptionDA,
  };

  console.log(filtersObject)
 
  const handleTypeSelectCandidate = (e:any) => {
    setSelectedOptionCandidate(e);
  };

  const handleTypeSelectName = (e:any) => {
    setSelectedOptionName(e);
  };
 
  const handleTypeSelectCandidateCity = (e:any) => {
    setSelectedOptionCandidateCity(e);
  };
 
  const handleTypeSelectCandidateAge = (e:any) => {
    setSelectedOptionCandidateAge(e);
  };

  const handleTypeSelectCandidateCollaborator = (e:any) => {
    setSelectedOptionCandidateCollaborator(e);
  };

  const handleTypeSelectCandidateState = (e:any) => {
    setSelectedOptionCandidateState(e);
  };

  const handleTypeSelectCollaboratorSL = (e:any) => {
    setSelectedOptionCollaboratorSL(e);
  };

  const handleTypeSelectCP = (e:any) => {
    setSelectedOptionCP(e);
  };

  const handleTypeSelectDA = (e:any) => {
    setSelectedOptionDA(e);
  };
 
  const sendBack = async (s:any) => {
    let selectedFilters: Record<string,any> = {'isFiltered':true,'candidate':"",'home_city':"", 
      'Classe_Eta': "", 'type_collaborator_status': "", 'candidate_state': "", 'sl_bench':"", 'name':"", 
      'Categoria_protetta_filtro':"", 'Dote_attiva_filtro':""}
    props.setJ([]);
    props.timeE(false);
    for (const tag in selectedFilters) {
      if (tag === 'isFiltered') continue;
      if (tag === 'type_collaborator_status' && filtersObject[tag].length !== 0) {
        let tupleString = '('
        for (const pc of filtersObject[tag]) {
          let tempValue = ""
          if (pc.value === 'Non Collaboratore') {
            tempValue = "NC"
          }
          else {
            tempValue = "EC"
          }
          tupleString = tupleString.concat('"',tempValue,'"',",");
        }
        tupleString = tupleString.substring(0,tupleString.length - 1);
        tupleString = tupleString.concat(")")
        selectedFilters[tag] = tupleString
      }
      else if (tag === 'candidate_state' && filtersObject[tag].length !== 0) {
        let tupleString = '('
        for (const pc of filtersObject[tag]) {
          let tempValue = ""
          if (pc.value === 'Assunto R&S') {
            tempValue = "A"
          }
          else if (pc.value === 'Certificato') {
            tempValue = "I "
          }
          else if (pc.value === 'Candidato Non Idoneo') {
            tempValue = "NI"
          }
          else if (pc.value === 'Staff Interno') {
            tempValue = "SI"
          }
          else if (pc.value === 'Da Valutare') {
            tempValue = 'DV'
          }
          else {
            tempValue = "NA"
          }
          tupleString = tupleString.concat('"',tempValue,'"',",");
        }
        tupleString = tupleString.substring(0,tupleString.length - 1);
        tupleString = tupleString.concat(")")
        selectedFilters[tag] = tupleString
      }
      else if (filtersObject[tag].length !== 0) {
        let tupleString = '('
        for (const pc of filtersObject[tag]) {
          tupleString = tupleString.concat('"',pc.value,'"',",");
        }
        tupleString = tupleString.substring(0,tupleString.length - 1);
        tupleString = tupleString.concat(")")
        selectedFilters[tag] = tupleString
      }
    }
    fetch(`/api/databricks/applyFilterCandidate`, {
      method: 'POST',
      headers: { 'Accept': 'application/json',
                "Content-Type": "application/json"},
      body: JSON.stringify(selectedFilters)})
      .then(response => response.json())
      .then(function(data) {
        if (data.length === 0) {
          props.timeE(true);
        }
        else {
          props.timeE(false);
        }
        props.setJ(data)
      })
  }
 
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  return (
    <div className={sidebarClass}>
      <h1>Filtri</h1>
      <div className="filter-side">
        <CreatableSelect 
        isClearable 
        options={nameData}
        isMulti
        onChange={handleTypeSelectName}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Nome e Cognome"
        />
      </div>
      <div className="filter-side">
        <CreatableSelect 
        isClearable 
        options={candidateData}
        isMulti
        onChange={handleTypeSelectCandidate}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona ID Candidato"
        />
      </div>
      <div className="filter-side">
        <Select options={branchData}
        isMulti
        onChange={handleTypeSelectCandidateCity}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Città Candidato"
        />
      </div>
      <div className="filter-side">
        <Select options={candidateAge}
        isMulti
        onChange={handleTypeSelectCandidateAge}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Classe Età Candidato"
        />
      </div>
      <div className="filter-side">
        <Select options={candidateCollaborator}
        isMulti
        onChange={handleTypeSelectCandidateCollaborator}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Stato Collaboratore"
        />
      </div>
      <div className="filter-side">
        <Select options={candidateState}
        isMulti
        onChange={handleTypeSelectCandidateState}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Seleziona Stato Candidato"
        />
      </div>
      <div className="filter-side">
        <Select options={optionsSL}
        isMulti
        onChange={handleTypeSelectCollaboratorSL}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Collaboratore in disponibilità?"
        />
      </div>
      <div className="filter-side">
        <Select options={cpData}
        isMulti
        onChange={handleTypeSelectCP}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Categoria protetta?"
        />
      </div>
      <div className="filter-side">
        <Select options={daData}
        isMulti
        onChange={handleTypeSelectDA}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Dote Attiva?"
        />
      </div>
      <button
      onClick={sendBack}
      className="cta-red">
        {"Ricerca Candidati"}
      </button>
      <button onClick={props.toggleSidebar} className="sidebar-toggle">
        FILTRI
      </button>
    </div>
  );
};
export default SidebarRev;