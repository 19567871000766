import ReactDOM from 'react-dom/client';
import './index.css';
import { IsDirectMatchingProvider } from "./components/context/isDirectMatching";
import { IsReverseResultProvider } from './components/context/isReverseMatchingResult';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IsDirectResultProvider } from './components/context/isDirectMatchingResult';
import { IsMatchingRealTimeProvider } from './components/context/isMatchingRealTime';
import { DirectMatchingInputFieldsProvider } from './components/context/DirectMatchingInputFields';
import { BrowserRouter } from 'react-router-dom';
import { IsMatchingRealTimeResultProvider } from './components/context/isMatchingRealTimeResult';
import { IsMatchingRealTimeRevProvider } from './components/context/isMatchingRealTimeRev';
import { RealTimeDirectInputFieldsProvider } from './components/context/RealTimeDirectInputFields';
import { RealTimeReverseInputFieldsProvider } from './components/context/RealTimeReverseInputFields';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './components/auth/authConfig';
import { IsInfoPageProvider } from './components/context/isInfoPage';
import { InfoStringProvider } from './components/context/infoString';
import { IsPenalizedProvider } from './components/context/isPenalized';
import { CVPageProvider } from './components/context/cvPageContext';


const pca = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MsalProvider instance={pca}>
  <CVPageProvider>
  <BrowserRouter>
  <IsPenalizedProvider>
  <InfoStringProvider>
  <IsInfoPageProvider>
  <RealTimeReverseInputFieldsProvider>
  <RealTimeDirectInputFieldsProvider>
  <IsMatchingRealTimeRevProvider>
  <IsDirectResultProvider>
  <IsReverseResultProvider>
    <IsMatchingRealTimeResultProvider>
    <IsDirectMatchingProvider>
      <IsMatchingRealTimeProvider>
        <DirectMatchingInputFieldsProvider>
      <App />
        </DirectMatchingInputFieldsProvider>
      </IsMatchingRealTimeProvider>
    </IsDirectMatchingProvider>
    </IsMatchingRealTimeResultProvider>
  </IsReverseResultProvider>
  </IsDirectResultProvider>
  </IsMatchingRealTimeRevProvider>
  </RealTimeDirectInputFieldsProvider>
  </RealTimeReverseInputFieldsProvider>
  </IsInfoPageProvider>
  </InfoStringProvider>
  </IsPenalizedProvider>
  </BrowserRouter>
  </CVPageProvider>
  </MsalProvider>
  );

// strict mode: <React.StrictMode>
  //<App />
//</React.StrictMode>

reportWebVitals();
