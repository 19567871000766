import { useEffect, useState } from "react"
import { _get, _post } from "../../../../apiClient"
import LoaderComp from "../../../loader/Loader"
import { useIsMatchingRealTimeResult } from "../../../context/isMatchingRealTimeResult"
import IQG from "../Match/IQG/IQG"
import EmailClient from "../Match/Email/Email"
import './RealTimeMatch.css'
import EmailToCandidate from "../../ReverseMatching/Match/Email/EmailJob"
import CardDetailsRealTime from "./CardDetailsReal/CardDetailsRealTime"
import { useMsal } from "@azure/msal-react"
import { toast } from "react-toastify";
import { useIsInfoPage } from "../../../context/isInfoPage"
import { useInfoString } from "../../../context/infoString"
import InfoPage from "../../Info/InfoPage"
import { format } from "date-fns"

const RealTimeMatch = (props: any) => {
    // const [candidates, setCandidates] = useState<any>([])
    const [tempString, setTempString] = useState<any>("")
    const { toggleView } = useIsMatchingRealTimeResult();
    const [candidateDetails, setCandidateDetails] = useState({});
    const [timeE, setTimeE] = useState(false)
    const [matchDetails, setMatchDetails] = useState({});
    // const [explain, setExplain] = useState<any>({})
    const [dropdown, setDropdown] = useState(null);
    const [candidateId, setCandidateId] = useState("")

    const { accounts } = useMsal();

    const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
    const { infoString, setInfoString } = useInfoString();

    const getBack = () => {
      setInfoString(
        <div>
          <span style={{ color: "red", fontWeight:"bold" }}><h2>Definisci l’offerta di lavoro</h2></span>
          <p><h3>In questa sezione puoi definire tu le caratteristiche dell’offerta di lavoro qualora non fosse già inserita a sistema.
                  Usa i menu a tendina per definire le caratteristiche della posizione lavorativa. I campi mandatori sono quelli per cui è presente l’asterisco (Categoria professionale e città). Per la categoria professionale, puoi scegliere fra quelle presenti a sistema (derivati dalle ontologie standard Adecco) oppure puoi inserire un valore diverso che si avvicina maggiormente alla mansione da ricoprire per il ruolo di interesse. In questo caso, la ricerca semantica ti suggerirà le posizioni lavorative aperte più affini alla mansione ricercata.
                  Inoltre, usa la barra in basso per definire la distanza massima (in linea d’aria) entro cui cercare i candidati.
                  Clicca sul pulsante “Trova i profili in linea” per visualizzare i risultati della ricerca.

                  </h3></p>
        </div>
      )
        props.setCandidates([])
        toggleView(false);
      }
    const handleSearchCandidate = async(explainDic:any) => {
        let headersTemp:any = {}
        let expTemp:any = {}
        props.headers.forEach((value:any) => {
          if (value === 'jobTitle') {
    
            headersTemp[value] = 'Job title'
          }
    
          else if (value === 'city') {
            headersTemp[value] = 'Città lavoro'
          }
    
          else if (value === 'maxRadius') {
            headersTemp[value] ='Distanza massima'
          }

          else if (value === 'classe_eta') {
            headersTemp[value] ='Classe Età'
          }
    
          else if (value === 'Categoria_protetta_filtro') {
            headersTemp[value] ='Categoria Protetta'
          }

          else if (value === 'Dote_attiva_filtro') {
            headersTemp[value] ='Dote Attiva'
          }
    
          else if (value === 'skills') {
            headersTemp[value] ='Skill'
          }
    
          else if (value === 'education') {
            headersTemp[value] ='Education'
          }
    
          else if (value === 'language') {
            headersTemp[value] ='Lingue'
          }
    
          else if (value === 'license') {
            headersTemp[value] ='Patenti'
          }
        })
        props.setHeadersNew(headersTemp)
        fetch(`/api/realtime/embeddingsearch?username=${accounts[0]['username']}`, {
          method: 'POST',
          headers: { 'Accept': 'application/json',
                    "Content-Type": "application/json"},
          body: JSON.stringify(props.dataSearch)})
          .then(response => response.json())
          .then(function(data){ 
            props.setCandidates(data) 
            if (data.length === 0) {
              setTimeE(true)
            }

            else {
              if (Object.keys(props.explain).length == 0) {
              for (let i=0; i<=Math.min(4,data.length-1); i++) {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                            "Content-Type": "application/json"},
                  body: JSON.stringify({cv:"",jobDescription:"",motivo_match:data[i].Motivo_Match})})
                  .then(response => response.json())
                  .then(function(result) {
                    setTempString(result['spiegazione'])
                    expTemp[data[i].associateid] = result['spiegazione']
                    props.setExplain(expTemp)
                  } )
                  .catch(error => console.error('Error fetching data:', error));
              }
            }
          }})
          .catch(error => console.error('Error fetching data:', error));

      }
    
      const generateQuestion = () => {
        console.log(props.dataSearch["jobTitle"])
        props.setIqg(true);
      }

      const copyToClipboard = async (codFiscString: any, jobId: any) => {
              fetch(`/api/databricks/copy?username=${accounts[0]['username']}&associate_id=${codFiscString}&job_id=${jobId}`, {
                method: 'GET',
                headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
                .then(response => response.json())
                .then(data => console.log(data['result']))
                .catch(error => console.error('Error fetching data:', error));
                navigator.clipboard.writeText(codFiscString)
                toast.success('CF copiato!', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
    
      const getDetails = (fiscale:any) => {
        props.setCodFisc(fiscale);
        props.setAnagrafica(true);
        props.setOrdine(false);
        props.setIntervista(false);
        props.setCv(false);
        props.setDetails(true);
      }

      const toggleDropdown = async(id:any,candidate:any) => {
        setDropdown((prev) => {
          if (prev === id) {
            return null
          }
          else {
            return id
          }
        });

        if (!(candidate.associateid in props.explain)) {
          let expTemp:any = props.explain
          fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
            method: 'POST',
            headers: { 'Accept': 'application/json',
                      "Content-Type": "application/json"},
            body: JSON.stringify({cv:"",jobDescription:"",motivo_match:candidate.Motivo_Match})})
            .then(response => response.json())
            .then(data => setTempString(data['spiegazione']))
            .catch(error => console.error('Error fetching data:', error));
            expTemp[candidate.associateid] = tempString
          props.setExplain(expTemp)
        }
    
      };

      const toggleCheck = (id:any) => {
        let partial = props.checkList;
        if (props.checkList.includes(id)) {
          partial = partial.filter(function(e:any) { return e !== id })
          props.setCheckList(partial)
          
        }
        else {
          props.setCheckList([...props.checkList,id])
        }
    
        console.log(props.checkList)
      };

      const generateEmailToCandidate = (candidate:any,ds:any) => {
        setMatchDetails({
                        'professional_category':ds['jobTitle'],
                        'Motivo_Match':candidate['Motivo_Match'],
                        'Distanza_km':candidate['distance'],
                        'work_city':ds['city'],
        });
        setCandidateId(candidate.associateid)
        props.setEmailC(true);
      }
    
      const generateEmail = (candidate: any) => {
        setCandidateDetails({'Motivo_Match':candidate['Motivo_Match'],
                             'Distanza_km':candidate['distance']});
        setCandidateId(candidate.associateid)
        props.setEmail(true);
      }

      useEffect(() => {
        setInfoString(<div>
          <span style={{ color: "red", fontWeight:"bold" }}><h2>Risultati ricerca Talent Finder</h2></span>
          <p><h3>In questa pagina trovi i risultati della ricerca effettuata. 
                Sulla sinistra trovi un riassunto delle caratteristiche della posizione lavorativa. 
                Le schede, invece, mostrano i risultati (candidati) ordinati per score decrescente (quindi dal più affine al meno affine). Ogni scheda del candidato riporta alcune informazioni rilevanti come: 
                <ul>
                  <li>Nome e Cognome</li>
                  <li>Codice fiscale (da copiare con l’apposito tasto in caso si voglia riportarlo su Olè e/o FO)</li>
                  <li>Distanza domicilio – luogo di lavoro</li>
                  <li>Categoria professionale di interesse del candidato</li>
                  <li>Max step pipeline (lo step della pipeline più avanzato in cui è inserito un candidato al momento)</li>
                  <li>Collaboratore in disponibilità (Staff Leasing bench)</li>
                  <li>Info match (cliccando sul bottone, l’AI generativa spiega il perché quel candidato è affine alla posizione lavorativa)</li>
                  <li>Stato candidato (Certificato / Non certificato)</li>
                </ul>
                Inoltre, è presente un riquadro che permettere di “spuntare” i candidati già visualizzati. Il flag rimane attivo per l’intera sessione.
                In basso ad ogni scheda, sono presenti 4 bottoni:
                <ul>
                  <li>Dettagli candidato, che reindirizza ad una pagina di dettaglio contenente le informazioni anagrafiche del candidato, gli step della pipeline in cui è stato inserito da Gennaio 2023 ad oggi, le interviste effettuate dai recruiter da Gennaio 2023 ad oggi ed il CV</li>
                  <li>Email Candidato, che permette di creare una mail di presentazione della posizione lavorativa al candidato</li>
                  <li>Genera Domande, che permette di generare le domande per il colloquio con il candidato per la posizione lavorativa</li>
                  <li>Email cliente, permette di generare la mail per presentare il candidato al cliente per la posizione lavorativa specifica</li>
                </ul>
              </h3>
            </p>
        </div>)
        if (props.candidates.length === 0) {
          handleSearchCandidate(props.explain);
        }
      }, [])

      const togglePenalized = () => {
        props.setCandidates([])
        props.dataSearch["isPenalty"] = Math.abs(props.dataSearch["isPenalty"]-1)
        handleSearchCandidate({});
        props.setIsPenalized(Math.abs(props.isPenalized-1))
      }

      const toggleLastActivity = () => {
        props.setCandidates([])
        props.dataSearch["isOrderLastActivity"] = Math.abs(props.dataSearch["isOrderLastActivity"]-1)
        handleSearchCandidate({});
        props.setIsOrderLastActivity(Math.abs(props.isOrderLastActivity-1))
      }

    return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
      <div>
      {(props.emailC)?
        <EmailToCandidate email={props.emailC} setEmail={props.setEmailC} job={matchDetails} candidateId={candidateId} jobId={"realtime"} emails={props.emails} setEmails={props.setEmails}></EmailToCandidate>
      :
      <div>
      {(props.email) ?
      <div>
        <EmailClient email={props.email} setEmail={props.setEmail} candidate={candidateDetails} candidateId={candidateId} jobId={"realtime"} category={props.dataSearch['jobTitle']} workCity={props.dataSearch["city"]} emailsC={props.emailsC} setEmailsC={props.setEmailsC}></EmailClient>
      </div>
      :
      <div>
        {(props.isDetails) 
        ?
          <div>
            <div>
              <CardDetailsRealTime cvs={props.cvs} setCvs={props.setCvs} codFisc={props.codFisc}
                setCodFisc={props.setCodFisc} 
                setDetails={props.setDetails} urlPDF={props.urlPDF} setUrlPDF={props.setUrlPDF} 
                isAI = {props.isAI} setIsAI = {props.setIsAI}
                anagrafiche={props.anagrafiche}
                setAnagrafiche={props.setAnagrafiche}
                interviste={props.interviste}
                setInterviste={props.setInterviste}
                ordini={props.ordini}
                setOrdini={props.setOrdini}
                anagrafica={props.anagrafica}
                setAnagrafica={props.setAnagrafica}
                ordine={props.ordine}
                setOrdine={props.setOrdine}
                intervista={props.intervista}
                setIntervista={props.setIntervista}
                cv={props.cv}
                setCv={props.setCv}
                timeAnagrafica={props.timeAnagrafica}
                setTimeAnagrafica={props.setTimeAnagrafica}
                timeOrdine={props.timeOrdine}
                setTimeOrdine={props.setTimeOrdine}
                timeIntervista={props.timeIntervista}
                setTimeIntervista={props.setTimeIntervista}
                timeCvs={props.timeCvs}
                setTimeCvs={props.setTimeCvs}></CardDetailsRealTime>
            </div>
            </div>
        : 
        <div>
          {(props.iqg) ?
          <div>
            <IQG category={props.dataSearch["jobTitle"]} iqg={props.iqg} setIqg={props.setIqg} candidateId={candidateId} jobId={"realtime"} questions={props.questions} setQuestions={props.setQuestions}></IQG>    
          </div>
          :
          <div>
            <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
              <span className="center-span">
                  <input type="checkbox" className="check-with-label-idk" id="home-btn" checked={props.isPenalized} onClick={togglePenalized}></input>
                  <label className="label-for-check-idk">Prioritizza Candidati con attività recenti</label>
              </span>
              <span className="center-span">
                  <input type="checkbox" className="check-with-label-idk" id="home-btn" checked={props.isOrderLastActivity} onClick={toggleLastActivity}></input>
                  <label className="label-for-check-idk">Ordina per data ultima attività</label>
              </span>
            </div>
          </div>
          {(props.candidates.length > 0 || timeE)? 
            <div className="row-main">
              <div className="column-table-direct">
                {props.headers.filter((item:any) => {
                let toExclude = ['isPenalty',
                  'candidate_state',
                  'isOrderLastActivity'
                ]
                if (props.dataSearch[item].length == 0) {
                  toExclude.push(item)
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
                <div className="inline-div-super-dir">
                    <h2 className="test-h3">{props.headersNew[header]}</h2>
                  <h5>{props.dataSearch[header]}</h5>
                </div>
                ))}
              </div>
              <div className="sovra-container-direct">
              {(timeE &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                  {props.candidates.map((candidate: any) => (
                    <div key={candidate.associateid} className="candidate-card-dir">
                      <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(candidate.associateid)} checked={props.checkList.includes(candidate.associateid)}></input>
                      <label className="label-for-check">Visualizzato</label>
                      <div className="sub-container-lr">
                      <div className="section-infos-right-dir">
                          <p><strong>Nome: </strong>{candidate.name} {candidate.surname}</p>
                          <p><strong>CF: </strong>{candidate.associateid} <button className="cta-red-mat" onClick={() => copyToClipboard(candidate.associateid,"")}>Copia CF</button></p>
                          <p><strong>Esperienza: </strong>{candidate.experience}</p>
                          <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {candidate.Comune_Domicilio} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {props.dataSearch['city']} </strong>pari a {Math.round(Number(candidate.distance))} KM</p>
                          <p><strong>Max Step Pipeline:</strong> {candidate.max_step_pipeline_overall}</p>
                          <p><strong>Collaboratore in disponibilità:</strong> {candidate.sl_bench}</p>
                          <p><strong>Data ultima attività:</strong> {format(new Date(candidate.data_ultima_attivita), "yyyy-MM-dd")}</p>
                        </div>
                        <div className="section-infos-left">
                        <p
                            className= {
                              candidate.Stato_Candidato === "Certificato" 
                                                        ? "green-tag" 
                                                        : candidate.Stato_Candidato === "Da valutare"
                                                        ? "orange-tag"
                                                        : candidate.Stato_Candidato === "Non idoneo"
                                                        ? "red-tag"
                                                        : ""
                            }
                            >
                            {candidate.Stato_Candidato}
                          </p>
                        </div>
                      </div>
                      <div id="dropdown-full">
                        <div 
                          key={candidate.associateid}
                          className={`dropdown-slot ${dropdown === candidate.associateid ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(candidate.associateid,candidate)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === candidate.associateid ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === candidate.associateid ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === candidate.associateid ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                              {dropdown === candidate.associateid? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === candidate.associateid ? '' : 'match-reason-hidden'}`}>
                          <span className={`match-reason ${dropdown === candidate.associateid ? '' : 'match-reason-hidden'}`}>
                            {(props.explain[candidate.associateid] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                          </span>
                          {dropdown === candidate.associateid? props.explain[candidate.associateid]: ""}
                        </div>
                        <div className="row-button">
                          <button className="cta-red-mat" onClick={() => getDetails(candidate.associateid)}>
                            {"Dettagli Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmailToCandidate(candidate,props.dataSearch)}>
                            {"Email Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateQuestion()}>
                            {"Genera Domande"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmail(candidate)}>
                            {"Email Cliente"}
                          </button>
                        </div>
                      </div>
                  </div>
                ))}
            </div>
            </div>
          : 
          <div className="div-center">
            <div>
              <h3> Ricerca candidati in corso</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
          </div>}
          </div>
          }
          </div>
          }
          </div>
          }
          </div>
          }
          <div style={{textAlign:"center"}}>
            <h6>
              <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
              Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
            </h6>
          </div>
        </div>
          }
      </div>)
}

export default RealTimeMatch

