import { useEffect, useState } from "react";
import Ordini from "../../../DirectMatching/Match/CardDetails/Buttons/Ordini";
import Interviste from "../../../DirectMatching/Match/CardDetails/Buttons/Interviste";
import CV from "../../../DirectMatching/Match/CardDetails/Buttons/CV";
import JobInfo from "./Buttons/JobInfo";
import "./CardDetailsJob.css";

function CardDetailsJob(props: any) {

    const getInfojob = async() => {
        props.setJobInfo(true);
        props.setOrdine(false);
        props.setIntervista(false);
        props.setCv(false);
    }

    const getOrdini = async() => {
        props.setJobInfo(false);
        props.setOrdine(true);
        props.setIntervista(false);
        props.setCv(false);
    }

    const getInterviste= async() => {
        props.setJobInfo(false);
        props.setOrdine(false);
        props.setIntervista(true);
        props.setCv(false);
    }

    const getCv = async() => {
        props.setJobInfo(false);
        props.setOrdine(false);
        props.setIntervista(false);
        props.setCv(true);
    }

    const backDetails = () => {
        props.setUrlPDF("");
        props.setCodFisc("");
        props.setCvs("");
        props.setJobInfos([])
        props.setInterviste([])
        props.setOrdini([])
        props.setTimeOrdine(false)
        props.setTimeJobInfo(false)
        props.setTimeIntervista(false)
        props.setTimeCvsJob(false)
        props.setDetails(false);
    }
    
    const getBack = () => {
        props.toggleView(true);
    }


    return (
        <div>
            <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-home bx-sm bx-border' id='home-btn-4' onClick={getBack}>
                    <span className='home-btn-text-2'>
                    Torna alla schermata Home
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backDetails}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
            <div className="center-div">
                <button className="cta-red-mat" onClick={getInfojob}>
                                {"Info Offerta"}
                </button>
                <button className="cta-red-mat" onClick={getOrdini}>
                                {"Storico Ordini"}
                </button>
                <button className="cta-red-mat" onClick={getInterviste}>
                                {"Storico Interviste"}
                </button>
                <button className="cta-red-mat" onClick={getCv}>
                                {"CV Candidato"}
                </button>
            </div>
            
            {(props.jobInfo)?<JobInfo job_id={props.job['job_id']} codFisc={props.codFisc} jobInfos={props.jobInfos} setJobInfos={props.setJobInfos}></JobInfo>:
            <div>
                {(props.ordine)?<Ordini codFisc={props.codFisc} ordini={props.ordini} setOrdini={props.setOrdini} timeOrdine={props.timeOrdine} setTimeOrdine={props.setTimeOrdine}></Ordini>:
                <div>
                    {(props.intervista)?<Interviste codFisc={props.codFisc} interviste={props.interviste} setInterviste={props.setInterviste} timeIntervista={props.timeIntervista} setTimeIntervista={props.setTimeIntervista}></Interviste>:
                    <div>
                        {(props.cv && <CV cvs={props.cvs} setCvs={props.setCvs} codFisc={props.codFisc} urlPDF={props.urlPDF} setUrlPDF={props.setUrlPDF} isAI = {props.isAI} setIsAI = {props.setIsAI} timeCvs={props.timeCvsJob} setTimeCvs={props.setTimeCvsJob}></CV>)}
                    </div>
                }
                </div>
            }
            </div>
            }
        </div>
        
    )
}

export default CardDetailsJob;