import "./DirectMatching.css";
import TableJobs from "./Job/Job";
import { useIsMatchingRealTime } from "../../context/isMatchingRealTime";
import RealTime from "./RealTime/RealTime";
import { InfoRounded } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useIsInfoPage } from "../../context/isInfoPage";

const DirectMatching = (props:any) => {
  const { isMatchingRealTime } = useIsMatchingRealTime();
  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();

  const setPage = () => {
    toggleViewInfoPage()
  }

  return (
    <div>
      <div className="text-center">
          <h1 className="mb0 desktop">GPT Talent <span style={{ color: "red" }}> Finder <Button onClick={setPage}><InfoRounded></InfoRounded></Button></span></h1>
          <p className="mt0 desktop">
          </p>
      </div>
      {isMatchingRealTime? <TableJobs></TableJobs> : <RealTime ></RealTime>}
    </div>
  );
}

export default DirectMatching;
