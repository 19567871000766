import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import "./Match.css"
import LoaderComp from "../../../loader/Loader";
import { useIsDirectResult } from "../../../context/isDirectMatchingResult";
import CardDetails from "./CardDetails/CardDetails";
import IQG from "./IQG/IQG";
import { format } from "date-fns";
import EmailClient from "./Email/Email";
import EmailToCandidate from "../../ReverseMatching/Match/Email/EmailJob";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useIsInfoPage } from "../../../context/isInfoPage";
import { useInfoString } from "../../../context/infoString";
import InfoPage from "../../Info/InfoPage";
import { useIsPenalized } from "../../../context/isPenalized";
import SidebarBatch from "./SidebarBatchMatch/SidebarBatch";
import { Link } from "react-router-dom";
import { useCVPageContext } from "../../../context/cvPageContext";


function TableMatches(props: any) {
  // const [matches, setMatches] = useState([])
  const [timeElapsed, setTimeElapsed] = useState(false);
  const { toggleView } = useIsDirectResult();
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [candidateId, setCandidateId] = useState("");
  const [matchDetails, setMatchDetails] = useState({});
  const [dropdown, setDropdown] = useState(null);
  const [quit, setQuit] = useState(false);
  const [sidebarOpen, setSideBarOpen] = useState(false);

  const { accounts } = useMsal();

  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
  const { infoString, setInfoString } = useInfoString();

  const{
        codFiscP,
        setCodFiscP,
        setIsAIP,
        isAIP,
        setUrlPDFP,
        urlPDFP,
        timeCvsP,
        setTimeCvsP,
        resetAllFields
        } = useCVPageContext();

  const findCandidate = async (announcement_id:any,isPenalized:any,explainDic:any, findString:any) => {
    let expTemp:any = {}
    let headersTemp:any = {}
    props.headers.forEach((value:any) => {
      if (value === 'announcement_id') {

        headersTemp[value] = 'Job ID'
      }

      else if (value === 'announcement_type') {
        headersTemp[value] = 'Tipologia'
      }

      else if (value === 'business_line') {
        headersTemp[value] ='Tipo di Contratto'
      }

      else if (value === 'work_order_number') {
        headersTemp[value] ='Numero Ordine'
      }

      else if (value === 'offer_number') {
        headersTemp[value] ='Numero Offerta'
      }

      else if (value === 'branch') {
        headersTemp[value] ='CDC'
      }

      else if (value === 'contract_duration') {
        headersTemp[value] ='Durata contratto'
      }

      else if (value === 'professional_category') {
        headersTemp[value] ='Categoria professionale'
      }

      else if (value === 'client_desc') {
        headersTemp[value] ='Cliente'
      }

      else if (value === 'work_city') {
        headersTemp[value] ='Città offerta'
      }

      else if (value === 'Categoria_protetta') {
        headersTemp[value] ='Categoria protetta'
      }
    })
    props.setHeadersNew(headersTemp)
    fetch(`/api/databricks/jobdesc?announcement_id=${announcement_id}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json' } })
      .then(response => response.json())
      .then(function(data) {
        if (announcement_id === "") {
          props.setMatches([])
        }

        else {
          let basicString = `/api/databricks/findCandidate?`
          basicString = basicString.concat(findString)
          fetch(`${basicString}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(result) {
              props.setMatches(result)
              if (result.length === 0) {
                setTimeElapsed(true);
              }
              if (Object.keys(explainDic).length == 0) {
                props.setExplain({})
              for (let i=0; i<=Math.min(4,result.length-1); i++) {
                fetch(`/api/databricks/cvcand?associate_id=${result[i].Codice_Fiscale}`, {
                  method: 'GET',
                  headers: { 'Accept': 'application/json' } })
                  .then(response => response.json())
                  .then(function(cv) {
                    if (cv['cv'] !== "" && data['job'] !== "") {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                   "Content-Type": "application/json"
                         },
                        body: JSON.stringify({cv:cv['cv'],jobDescription:data['job'],motivo_match:result[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(explain) {
                          if (explain['spiegazione']) {
                            expTemp[result[i].Codice_Fiscale] = explain['spiegazione']
                          }
                          else {
                            fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                              method: 'POST',
                              headers: { 'Accept': 'application/json',
                                         "Content-Type": "application/json",
                                         'X-User-Id': accounts[0]['username']
                               },
                              body: JSON.stringify({cv:cv['cv'],jobDescription:"",motivo_match:result[i].Motivo_Match}) })
                              .then(response => response.json())
                              .then(function(explainNoJob) {
                                expTemp[result[i].Codice_Fiscale] = explainNoJob['spiegazione']
                              })
                          }
                        })
                    }
                    else {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                  "Content-Type": "application/json",
                                'X-User-Id': accounts[0]['username']},
                        body: JSON.stringify({cv:"",jobDescription:"",motivo_match:result[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(lastRes) {
                          expTemp[result[i].Codice_Fiscale] = lastRes['spiegazione']
                        })
                    }
                    props.setExplain(expTemp)
                  })
                  .catch(error => console.error('Error fetching data:', error));
                  
              }
            }})
            .catch(error => console.error('Error fetching data:', error));
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const getBack = () => {
    props.setMatches([])
    setInfoString(
      <div>
        <span style={{ color: "red", fontWeight:"bold" }}><h2>Opportunità aperte</h2></span>
        <p><h3>Benvenuto nella home page del GPT Talent Finder.
                In questa sezione puoi utilizzare l’algoritmo di matching per trovare i migliori candidati per una posizione lavorativa.
                Nella tabella principale trovi tutte le posizioni lavorative aperte ed inserite a sistema entro la mezzanotte del giorno precedente. Utilizzando i filtri presenti nel menù a scomparsa sulla sinistra puoi cercare la posizione lavorativa di tuo interesse. Inoltre, nell’angolo in alto a sinistra della tabella puoi trovare alcune funzionalità che ti permetteranno di filtrare i dati per una specifica colonna, nascondere le colonne che non ti interessa visualizzare e modificare la visualizzazione della tabella stessa.
                Una volta individuata la posizione lavorativa di interesse, clicca sul tasto “Matching” per visualizzare i candidati più affini. I risultati mostreranno i candidati in un intorno (in linea d’aria) di 25km dal luogo di lavoro.
                Se, invece, la posizione lavorativa non è presente a sistema clicca sul bottone “Opportunità aperte” per passare alla pagina “Definisci l’offerta di lavoro”
                </h3></p>
      </div>
    )
    setQuit(true);
    toggleView(true);
  }

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  
  const generateQuestion = () => {
    props.setIqg(true);
  }

  const sendCodFisc = (fiscale:any) => {
    setCodFiscP("");
    setUrlPDFP("");
    setIsAIP(false);
    setTimeCvsP(false);
    setCodFiscP(fiscale.Codice_Fiscale);
    console.log(codFiscP)
    console.log(fiscale.Codice_Fiscale)
  }

  const getDetails = (fiscale:any) => {
    props.setCodFisc(fiscale);
    props.setAnagrafica(true);
    props.setOrdine(false);
    props.setIntervista(false);
    props.setCv(false);
    props.setDetails(true);
  }

  const generateEmailToJob = (candidate: any) => {
    setCandidateDetails(candidate);
    setCandidateId(candidate.Codice_Fiscale)
    props.setEmail(true);
  }

  const generateEmailToCandidate = (row:any,candidate:any) => {
    setMatchDetails({'professional_category':row['professional_category'],
                    'Motivo_Match':candidate['Motivo_Match'],
                    'Distanza_km':candidate['Distanza_km'],
                    'work_city':row['work_city']
    });
    setCandidateId(candidate.Codice_Fiscale)
    props.setEmailC(true);
  }

  useEffect(() => {
    if (props.matches.length === 0) {
      findCandidate(props.announcement_id,props.isPenalized,props.explain,`announcement_id=${props.announcement_id}&isPenalized=${props.isPenalized}&isOrderLastActivity=${props.isOrderLastActivity}&isFiltered=0&Classe_Eta=""&candidate_state=""&Dote_attiva_filtro=""&Categoria_protetta_filtro=""`);
    }
    setInfoString(<div>
      <span style={{ color: "red", fontWeight:"bold" }}><h2>Risultati ricerca Talent Finder</h2></span>
      <p><h3>In questa pagina trovi i risultati della ricerca effettuata. 
            Sulla sinistra trovi un riassunto delle caratteristiche della posizione lavorativa. 
            Le schede, invece, mostrano i risultati (candidati) ordinati per score decrescente (quindi dal più affine al meno affine). Ogni scheda del candidato riporta alcune informazioni rilevanti come: 
            <ul>
              <li>Nome e Cognome</li>
              <li>Codice fiscale (da copiare con l’apposito tasto in caso si voglia riportarlo su Olè e/o FO)</li>
              <li>Distanza domicilio – luogo di lavoro</li>
              <li>Categoria professionale di interesse del candidato</li>
              <li>Max step pipeline (lo step della pipeline più avanzato in cui è inserito un candidato al momento)</li>
              <li>Collaboratore in disponibilità (Staff Leasing bench)</li>
              <li>Info match (cliccando sul bottone, l’AI generativa spiega il perché quel candidato è affine alla posizione lavorativa)</li>
              <li>Stato candidato (Certificato / Non certificato)</li>
            </ul>
            Inoltre, è presente un riquadro che permettere di “spuntare” i candidati già visualizzati. Il flag rimane attivo per l’intera sessione.
            In basso ad ogni scheda, sono presenti 4 bottoni:
            <ul>
              <li>Dettagli candidato, che reindirizza ad una pagina di dettaglio contenente le informazioni anagrafiche del candidato, gli step della pipeline in cui è stato inserito da Gennaio 2023 ad oggi, le interviste effettuate dai recruiter da Gennaio 2023 ad oggi ed il CV</li>
              <li>Email Candidato, che permette di creare una mail di presentazione della posizione lavorativa al candidato</li>
              <li>Genera Domande, che permette di generare le domande per il colloquio con il candidato per la posizione lavorativa</li>
              <li>Email cliente, permette di generare la mail per presentare il candidato al cliente per la posizione lavorativa specifica</li>
            </ul>
          </h3>
        </p>
    </div>)
  }, [])

  const copyToClipboard = async (codFiscString: any, jobId: any) => {
        fetch(`/api/databricks/copy?username=${accounts[0]['username']}&associate_id=${codFiscString}&job_id=${jobId}`, {
          method: 'GET',
          headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
          .then(response => response.json())
          .then(data => console.log(data['result']))
          .catch(error => console.error('Error fetching data:', error));
          navigator.clipboard.writeText(codFiscString)
          toast.success('CF copiato!', {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
      }



  const toggleDropdown = async (id:any, candidate:any) => {
    setDropdown((prev) => {
      if (prev === id) {
        return null
      }
      else {
        return id
      }
    });
    if (!(candidate.Codice_Fiscale in props.explain)) {
      let expTemp:any = props.explain
      fetch(`/api/databricks/jobdesc?announcement_id=${props.announcement_id}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(job) {
          fetch(`/api/databricks/cvcand?associate_id=${candidate.Codice_Fiscale}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(cv) {
              if (cv['cv'] !== "" && job['job'] !== "") {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                             "Content-Type": "application/json",
                             'X-User-Id': accounts[0]['username']
                   },
                  body: JSON.stringify({cv:cv['cv'],jobDescription:job['job'],motivo_match:candidate.Motivo_Match}) })
                  .then(response => response.json())
                  .then(function(explain) {
                    if (explain['spiegazione']) {
                      expTemp[candidate.Codice_Fiscale] = explain['spiegazione']
                    }
                    else {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                   "Content-Type": "application/json",
                                   'X-User-Id': accounts[0]['username']
                         },
                        body: JSON.stringify({cv:['cv'],jobDescription:"",motivo_match:candidate.Motivo_Match}) })
                        .then(response => response.json())
                        .then(function(explainNoJob) {
                          expTemp[candidate.Codice_Fiscale] = explainNoJob['spiegazione']
                        })
                    }
                  })
              }
              else {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                            "Content-Type": "application/json",
                          'X-User-Id': accounts[0]['username']},
                  body: JSON.stringify({cv:"",jobDescription:"",motivo_match:candidate.Motivo_Match})})
                  .then(response => response.json())
                  .then(function(lastRes) {
                    expTemp[candidate.Codice_Fiscale] = lastRes['spiegazione']
                  })
              }
              props.setExplain(expTemp)
              })
            })
    }
  };

  const toggleCheck = (id:any) => {
    let partial = props.checkList;
    if (props.checkList.includes(id)) {
      partial = partial.filter(function(e:any) { return e !== id })
      props.setCheckList(partial)
      
    }
    else {
      props.setCheckList([...props.checkList,id])
    }

  };

  const togglePenalized = () => {
    props.setMatches([])
    console.log(Math.abs(props.isPenalized-1))
    findCandidate(props.announcement_id,Math.abs(props.isPenalized-1),{},`announcement_id=${props.announcement_id}&isPenalized=${Math.abs(props.isPenalized-1)}&isOrderLastActivity=${props.isOrderLastActivity}&isFiltered=0&Classe_Eta=""&candidate_state=""&Dote_attiva_filtro=""&Categoria_protetta_filtro=""&Classe_Eta=""&candidate_state=""&Dote_attiva_filtro=""&Categoria_protetta_filtro=""`);
    props.setIsPenalized(Math.abs(props.isPenalized-1))
  }

  const toggleLastActivity = () => {
    props.setMatches([])
    findCandidate(props.announcement_id,props.isPenalized,{},`announcement_id=${props.announcement_id}&isPenalized=${props.isPenalized}&isOrderLastActivity=${Math.abs(props.isOrderLastActivity-1)}&isFiltered=0&Classe_Eta=""&candidate_state=""&Dote_attiva_filtro=""&Categoria_protetta_filtro=""&Classe_Eta=""&candidate_state=""&Dote_attiva_filtro=""&Categoria_protetta_filtro=""`);
    props.setIsOrderLastActivity(Math.abs(props.isOrderLastActivity-1))
  }


  return (
  <div>
      {isInfoPage && <div> <InfoPage infoString={infoString}></InfoPage> </div>}
    <div>
      {(props.emailC)?
        <EmailToCandidate email={props.emailC} setEmail={props.setEmailC} job={matchDetails} jobId={props.announcement_id} candidateId={candidateId} emails={props.emails} setEmails={props.setEmails}></EmailToCandidate>
      :
    <div>
    {(props.email) ?
    <div>
      <EmailClient email={props.email} setEmail={props.setEmail} candidate={candidateDetails} jobId={props.announcement_id} candidateId={candidateId} category={props.row["professional_category"]} workCity={props.row["work_city"]} emailsC={props.emailsC} setEmailsC={props.setEmailsC}></EmailClient>
    </div>
    :
    <div>
      {(props.isDetails) 
      ?
      <div>
      <div>
          <CardDetails cvs={props.cvs} setCvs={props.setCvs} 
          codFisc={props.codFisc} setCodFisc={props.setCodFisc} urlPDF={props.urlPDF} 
          setUrlPDF={props.setUrlPDF} userId={props.userId} 
          isAI = {props.isAI} setIsAI = {props.setIsAI} 
          isDetails = {props.isDetails} 
          setDetails = {props.setDetails} 
          toggleView = {toggleView} 
          jobId={props.announcement_id}
          anagrafiche={props.anagrafiche}
          setAnagrafiche={props.setAnagrafiche}
          interviste={props.interviste}
          setInterviste={props.setInterviste}
          ordini={props.ordini}
          setOrdini={props.setOrdini}
          anagrafica={props.anagrafica}
          setAnagrafica={props.setAnagrafica}
          ordine={props.ordine}
          setOrdine={props.setOrdine}
          intervista={props.intervista}
          setIntervista={props.setIntervista}
          cv={props.cv}
          setCv={props.setCv}
          timeAnagrafica={props.timeAnagrafica}
          setTimeAnagrafica={props.setTimeAnagrafica}
          timeOrdine={props.timeOrdine}
          setTimeOrdine={props.setTimeOrdine}
          timeIntervista={props.timeIntervista}
          setTimeIntervista={props.setTimeIntervista}
          timeCvs={props.timeCvs}
          setTimeCvs={props.setTimeCvs}></CardDetails>
        </div>
      </div>
      : 
      <div>
        {(props.iqg) ?
        <div>
          <IQG category={props.row["professional_category"]} iqg={props.iqg} setIqg={props.setIqg} jobId={props.announcement_id} candidateId={candidateId} questions={props.questions} setQuestions={props.setQuestions}></IQG>    
        </div>
        :
        
        <div>
          <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
                {/* <span className='home-btn-text-6'>
                Ricarica pagina con vecchio punteggio
                </span> */}
                <span className="center-span">
                  <input type="checkbox" className="check-with-label-idk" id="home-btn" checked={props.isPenalized} onClick={togglePenalized}></input>
                  <label className="label-for-check-idk">Prioritizza Candidati con attività recenti</label>
                </span>
                <span className="center-span">
                  <input type="checkbox" className="check-with-label-idk" id="home-btn" checked={props.isOrderLastActivity} onClick={toggleLastActivity}></input>
                  <label className="label-for-check-idk">Ordina per data ultima attività</label>
                </span>
            </div>
          </div>
        {(props.matches.length > 0 || timeElapsed)? 
          <div className="row-main">
            <SidebarBatch isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} timeE={setTimeElapsed} setJ={props.setJ} jobId={props.announcement_id} isPenalized={props.isPenalized} username={accounts[0]['username']} findCandidate={findCandidate} setMatches={props.setMatches} isOrderLastActivity={props.isOrderLastActivity}/>
            <div className="column-table-direct">
              {props.headers.filter((item:any) => {
                let toExclude = [
                  "url",
                  "reception_date",
                  "contract_beginning_date",
                  "job_owner",
                  "job_title",
                  "work_province",
                  "skills","educations",
                  "languages",
                  "cards"
                ]
                if (props.row['work_order_number'] !== null) {
                  toExclude.push('offer_number')
                }
                else if (props.row['offer_number'] !== null) {
                  toExclude.push('work_order_number')
                }
                if (props.row[item] === null) {
                  toExclude.push(item)
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
              <div className="inline-div-super-dir">
                  <h2 className="test-h3">{props.headersNew[header]}</h2>
                  <h5>{props.row[header]}</h5>
              </div>
              ))}
            </div>
            
            <div className="sovra-container-direct">
            {(timeElapsed &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                {props.matches.map((candidate: any) => (
                  <div id={candidate.ID_Univoco} className="candidate-card-dir">
                    <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(candidate.ID_Univoco)} checked={props.checkList.includes(candidate.ID_Univoco)}></input>
                    <label className="label-for-check">Visualizzato</label>
                    <div className="sub-container-lr">
                    <div className="section-infos-right-dir">
                      <p><strong>Nome:</strong> {candidate.Nome} {candidate.Cognome}</p>
                      <p><strong>CF:</strong> {candidate.Codice_Fiscale} <button className="cta-red-mat" onClick={() => copyToClipboard(candidate.Codice_Fiscale,props.announcement_id)}>Copia CF</button> <Link to={`/cv?context=${encodeURIComponent(candidate.Codice_Fiscale)}`} target="_blank" onClick={() => sendCodFisc(candidate)}> <button className="cta-red-mat">{"CV Candidato"}</button></Link></p>
                      <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {candidate.Comune_Domicilio} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {props.row["work_city"]} </strong>pari a {Math.round(Number(candidate.Distanza_km))} KM</p>
                      <p><strong>Interesse:</strong> {candidate.Experience_Top}</p>
                      <p><strong>Max Step Pipeline:</strong> {candidate.max_step_pipeline_overall}</p>
                      <p><strong>Collaboratore in disponibilità:</strong> {candidate.sl_bench}</p>
                      <p><strong>Data ultima attività:</strong> {format(new Date(candidate.data_ultima_attivita), "yyyy-MM-dd")}</p>
                      </div>
                    <div className="section-infos-left">
                    <p
                          className= {
                            candidate.Stato_Candidato === "Certificato" 
                                                      ? "green-tag" 
                                                      : candidate.Stato_Candidato === "Da valutare"
                                                      ? "orange-tag"
                                                      : candidate.Stato_Candidato === "Non idoneo"
                                                      ? "red-tag"
                                                      : ""
                          }
                          >
                          {candidate.Stato_Candidato}
                        </p>
                    </div>
                    </div>
                    <div id="dropdown-full">
                        <div 
                          key={candidate.ID_Univoco}
                          className={`dropdown-slot ${dropdown === candidate.ID_Univoco ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(candidate.ID_Univoco,candidate)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === candidate.ID_Univoco  ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === candidate.ID_Univoco ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === candidate.ID_Univoco ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                                {dropdown === candidate.ID_Univoco? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === candidate.ID_Univoco ? '' : 'match-reason-hidden'}`}>
                          <span className={`match-reason ${dropdown === candidate.ID_Univoco ? '' : 'match-reason-hidden'}`}>
                            {(props.explain[candidate.Codice_Fiscale] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                          </span>
                          {dropdown === candidate.ID_Univoco? props.explain[candidate.Codice_Fiscale]: ""}
                        </div>
                        <div className="row-button">
                        <button className="cta-red-mat" onClick={() => getDetails(candidate.Codice_Fiscale)}>
                          {"Dettagli Candidato"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateEmailToCandidate(props.row,candidate)}>
                          {"Email Candidato"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateQuestion()}>
                          {"Genera Domande"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateEmailToJob(candidate)}>
                          {"Email Cliente"}
                        </button>
                      </div>
                      </div>
                </div>
              ))}
          </div>
          </div>
          
        : 
        <div className="div-center">
          <div >
            <h3> Ricerca candidati in corso</h3>
          </div>
          <div style={{width:"100px", margin:"auto"}}>
            <LoaderComp></LoaderComp>
          </div >
        </div>}
        </div>
        }
        </div>
        }
        </div>
        }
      </div>
      }
      <div style={{textAlign:"center", fontSize: "medium"}}>
        <h6>
        <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
        Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
        </h6>
      </div>
      </div>
    </div>
  )
}

export default TableMatches;

function setUrlPDFP(arg0: string) {
  throw new Error("Function not implemented.");
}
function setIsAIP(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function setTimeCvsP(arg0: boolean) {
  throw new Error("Function not implemented.");
}

