import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
  } from "react";

  interface CVPageContextType {
    codFiscP: any;
    urlPDFP: any;
    timeCvsP: any;
    isAIP: any;
    setCodFiscP:Dispatch<SetStateAction<any>>;
    setUrlPDFP:Dispatch<SetStateAction<any>>;
    setTimeCvsP:Dispatch<SetStateAction<any>>;
    setIsAIP:Dispatch<SetStateAction<any>>;
    resetAllFields: () => void;
  }

const CVPageContext = createContext<
CVPageContextType | undefined
>(undefined);
    
export const useCVPageContext = () =>
    useContext(
        CVPageContext,
    ) as CVPageContextType;

export const CVPageProvider: React.FC<{
    children: ReactNode;
  }> = ({ children }) => {
    const [codFiscP,setCodFiscP] = useState<any>("");
    const [isAIP,setIsAIP] = useState<any>(false);
    const [timeCvsP,setTimeCvsP] = useState<any>(false);
    const [urlPDFP,setUrlPDFP] = useState<any>("");

    const resetAllFields = () => {
      setCodFiscP("");
      setUrlPDFP("");
      setIsAIP(false);
      setTimeCvsP(false);
    };

    const contextValues: CVPageContextType = {
    codFiscP,
    isAIP,
    timeCvsP,
    urlPDFP,
    setCodFiscP,
    setIsAIP,
    setTimeCvsP,
    setUrlPDFP,
    resetAllFields
    };

    return (
        <CVPageContext.Provider value={contextValues}>
          {children}
        </CVPageContext.Provider>
      );

  }
