import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
  } from "react";
  
  interface RealTimeDirectInputFieldsContextType {
    selectedOptionJob: any;
    selectedOptionCandidateState: any;
    selectedOptionEta: any;
    selectedOptionCP: any;
    selectedOptionDA: any;
    selectedOptionBranch: any;
    selectedOptionEducation: Array<any>;
    selectedOptionSkill: Array<any>;
    selectedOptionLanguage: Array<any>;
    selectedOptionLicense: Array<any>;
    maxRadius: number;
    jobTitleData: string[];
    etaData: string[];
    daData: string[];
    cpData: string[];
    candidateStateData: string[];
    cityData: string[];
    skillsData: string[];
    educationData: string[];
    languageData: string[];
    licenseData: string[];
    setSelectedOptionJob:Dispatch<SetStateAction<any>>;
    setSelectedOptionCandidateState:Dispatch<SetStateAction<any>>;
    setSelectedOptionEta:Dispatch<SetStateAction<any>>;
    setSelectedOptionCP:Dispatch<SetStateAction<any>>;
    setSelectedOptionDA:Dispatch<SetStateAction<any>>;
    setSelectedOptionBranch:Dispatch<SetStateAction<any>>;
    setSelectedOptionEducation:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionSkill:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionLanguage:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionLicense:Dispatch<SetStateAction<any[]>>;
    setMaxRadius:Dispatch<SetStateAction<number>>;
    setJobTitleData:Dispatch<SetStateAction<any[]>>;
    setCandidateStateData:Dispatch<SetStateAction<any[]>>;
    setEtaData:Dispatch<SetStateAction<any[]>>;
    setDAData:Dispatch<SetStateAction<any[]>>;
    setCPData:Dispatch<SetStateAction<any[]>>;
    setCityData:Dispatch<SetStateAction<any[]>>;
    setSkillsData:Dispatch<SetStateAction<any[]>>;
    setEducationData:Dispatch<SetStateAction<any[]>>;
    setLanguageData:Dispatch<SetStateAction<any[]>>;
    setLicenseData:Dispatch<SetStateAction<any[]>>;
    resetAllFields: () => void;
  }
  
  const RealTimeDirectInputFieldsContext = createContext<
  RealTimeDirectInputFieldsContextType | undefined
  >(undefined);
  
  export const useRealTimeDirectInputFieldsContext = () =>
    useContext(
        RealTimeDirectInputFieldsContext,
    ) as RealTimeDirectInputFieldsContextType;
  
  export const RealTimeDirectInputFieldsProvider: React.FC<{
    children: ReactNode;
  }> = ({ children }) => {
    const [jobTitleData, setJobTitleData] = useState<string[]>([]);
    const [candidateStateData, setCandidateStateData] = useState<string[]>([]);
    const [etaData, setEtaData] = useState<string[]>([]);
    const [cpData, setCPData] = useState<string[]>([]);
    const [daData, setDAData] = useState<string[]>([]);
    const [cityData, setCityData] = useState<string[]>([]);
    const [skillsData, setSkillsData] = useState<string[]>([]);
    const [educationData, setEducationData] = useState<string[]>([]);
    const [languageData, setLanguageData] = useState<string[]>([]);
    const [licenseData, setLicenseData] = useState<string[]>([]);
    const[maxRadius, setMaxRadius] = useState<number>(25);
    const [selectedOptionJob, setSelectedOptionJob] = useState<any>([]);
    const [selectedOptionCandidateState, setSelectedOptionCandidateState] = useState<any>([]);
    const [selectedOptionEta, setSelectedOptionEta] = useState<any>([]);
    const [selectedOptionCP, setSelectedOptionCP] = useState<any>([]);
    const [selectedOptionDA, setSelectedOptionDA] = useState<any>([]);
    const [selectedOptionBranch, setSelectedOptionBranch] = useState<any>([]);
    const [selectedOptionEducation, setSelectedOptionEducation] = useState<any[]>([]);
    const [selectedOptionSkill, setSelectedOptionSkill] = useState<any[]>([]);
    const [selectedOptionLanguage, setSelectedOptionLanguage] = useState<any[]>([]);
    const [selectedOptionLicense, setSelectedOptionLicense] = useState<any[]>([]);

  
    const resetAllFields = () => {
      setSelectedOptionJob([]);
      setSelectedOptionCandidateState([]);
      setSelectedOptionEta([]);
      setSelectedOptionCP([]);
      setSelectedOptionDA([]);
      setSelectedOptionBranch([]);
      setSelectedOptionEducation([]);
      setSelectedOptionSkill([]);
      setSelectedOptionLanguage([]);
      setSelectedOptionLicense([]);
      setMaxRadius(25);
    };
  
    const contextValues: RealTimeDirectInputFieldsContextType = {
      jobTitleData,
      candidateStateData,
      etaData,
      cpData,
      daData,
      cityData,
      skillsData,
      educationData,
      languageData,
      licenseData,
      maxRadius,
      selectedOptionJob,
      selectedOptionCandidateState,
      selectedOptionEta,
      selectedOptionCP,
      selectedOptionDA,
      selectedOptionBranch,
      selectedOptionSkill,
      selectedOptionEducation,
      selectedOptionLanguage,
      selectedOptionLicense,
      setSelectedOptionJob,
      setSelectedOptionCandidateState,
      setSelectedOptionCP,
      setSelectedOptionDA,
      setSelectedOptionBranch,
      setSelectedOptionSkill,
      setSelectedOptionEducation,
      setSelectedOptionLanguage,
      setSelectedOptionLicense,
      setSelectedOptionEta,
      setJobTitleData,
      setEtaData,
      setCandidateStateData,
      setCPData,
      setDAData,
      setCityData,
      setSkillsData,
      setEducationData,
      setLanguageData,
      setLicenseData,
      setMaxRadius,
      resetAllFields,
    };
  
    return (
      <RealTimeDirectInputFieldsContext.Provider value={contextValues}>
        {children}
      </RealTimeDirectInputFieldsContext.Provider>
    );
  };
  