import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";

interface IsPenalizedContextType {
  isPenalized: any;
  setIsPenalized: Dispatch<SetStateAction<any>>
}

const IsPenalizedContext = createContext<
IsPenalizedContextType | undefined
>(undefined);

export const useIsPenalized = () => {
  const context = useContext(IsPenalizedContext);
  if (!context) {
    throw new Error(
      "Put in provider",
    );
  }
  return context;
};

export const IsPenalizedProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isPenalized, setIsPenalized] = useState(
    1
  );
  return (
    <IsPenalizedContext.Provider value={{ isPenalized, setIsPenalized}}>
      {children}
    </IsPenalizedContext.Provider>
  );
};
