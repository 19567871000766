import "./ReverseMatching.css";
import TableJobs from "./Candidate/Candidate"
import { useState } from "react";
import RealTimeRev from "./RealTimeRev/RealtimeRev";
import TableCandidate from "./Candidate/Candidate";
import { useIsMatchingRealTimeRev } from "../../context/isMatchingRealTimeRev";
import { useIsMatchingRealTime } from "../../context/isMatchingRealTime";
import { useIsInfoPage } from "../../context/isInfoPage";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";

const ReverseMatching = () => {
  const { isMatchingRealTimeRev, toggleViewRealRev } = useIsMatchingRealTimeRev();
  const { isMatchingRealTime } = useIsMatchingRealTime();
  const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();

  const setPage = () => {
    toggleViewInfoPage()
  }
  return (
    <div>
        <div className="text-center">
            <h1 className="mb0 desktop">GPT Talent <span style={{ color: "red" }}> Placer <Button onClick={setPage}><InfoRounded></InfoRounded></Button> </span> </h1>
            <p className="mt0 desktop">
            </p>
        </div>
        {isMatchingRealTimeRev? <TableCandidate></TableCandidate> : <RealTimeRev ></RealTimeRev>}
    </div>
  );
}

export default ReverseMatching








;
