import { useIsMatchingRealTime } from "../../../context/isMatchingRealTime";
import { useIsMatchingRealTimeResult } from "../../../context/isMatchingRealTimeResult";
import { useMemo, useState, useRef, ChangeEvent, useEffect } from "react";
import { _post } from "../../../../apiClient";
import usePopulateField from "../../../hooks/usePopulateFields";
import Select from 'react-select'
import RealtimeRevMatch from "../RealTimeRevMatch/RealtimeRevMatch";
import { toast } from "react-toastify";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import CreatableSelect from "react-select/creatable";
import { useIsMatchingRealTimeRev } from "../../../context/isMatchingRealTimeRev";
import { useRealTimeReverseInputFieldsContext } from "../../../context/RealTimeReverseInputFields";
import { useIsInfoPage } from "../../../context/isInfoPage";
import { useInfoString } from "../../../context/infoString";
import InfoPage from "../../Info/InfoPage";

const RealTimeRev = () => { 
  const { selectedOptionJob, 
    setSelectedOptionJob,
    selectedOptionEta,
    setSelectedOptionEta,
    selectedOptionCP,
    setSelectedOptionCP,
    selectedOptionDA,
    setSelectedOptionDA,
    selectedOptionBranch,
    setSelectedOptionBranch,
    selectedOptionEducation,
    setSelectedOptionEducation,
    selectedOptionSkill,
    setSelectedOptionSkill,
    selectedOptionLanguage,
    setSelectedOptionLanguage,
    selectedOptionLicense,
    setSelectedOptionLicense,
    maxRadius,
    setMaxRadius,
    jobTitleData,
    setJobTitleData,
    etaData,
    setEtaData,
    cpData,
    setCPData,
    daData,
    setDAData,
    cityData,
    setCityData,
    skillsData,
    setSkillsData,
    educationData,
    setEducationData,
    languageData,
    setLanguageData,
    licenseData,
    setLicenseData,
    experienceData,
    setExperienceData,
    selectedOptionExperience,
    setSelectedOptionExperience,
    resetAllFields} = useRealTimeReverseInputFieldsContext();
    const [headers, setHeaders] = useState({});
    const [dataPost, setDataPost] = useState<any>({});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { isMatchingRealTimeRev, toggleViewRealRev } = useIsMatchingRealTimeRev();
    const [isMatchingRealTimeRevResult, setIsMatchingRealRevResult] = useState(false);

    const [checkList, setCheckList] = useState<any[]>([])

    const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
    const { infoString, setInfoString } = useInfoString();

    const [isDetails, setDetails] = useState(false);
    const [iqg, setIqg] = useState(false);
    const [email, setEmail] = useState(false);
    const [emailC, setEmailC] = useState(false);
    const [emails, setEmails] = useState([]);
    const [emailsC, setEmailsC] = useState([]);
    const [questions, setQuestions] = useState([])
    const [matches, setMatches] = useState([])
    const [explain, setExplain] = useState<any>({})

    const [headersNew, setHeadersNew] = useState<any>({})

    const [anagrafiche, setAnagrafiche] = useState([])
    const [interviste, setInterviste] = useState([])
    const [ordini, setOrdini] = useState([])
    const [anagrafica, setAnagrafica] = useState(false);
    const [ordine, setOrdine] = useState(false);
    const [intervista, setIntervista] = useState(false);
    const [cv, setCv] = useState(false);
    const [timeAnagrafica, setTimeAnagrafica] = useState(false)
    const [timeOrdine, setTimeOrdine] = useState(false)
    const [timeCvs, setTimeCvs] = useState(false)
    const [timeIntervista, setTimeIntervista] = useState(false)
    const [cvs, setCvs] = useState("")
    const [urlPDF, setUrlPDF] = useState("");
    const [isAI, setIsAI] = useState(false)
    const [codFisc, setCodFisc] = useState("");
    const [jobInfo, setJobInfo] = useState(false);
    const [jobInfos, setJobInfos] = useState([])
    const [timeJobInfo, setTimeJobInfo] = useState(false)

    const [activeContent, setActiveContent] = useState(
      isMatchingRealTimeRev ? "realtime" : "normal"
    );
    const radiusInputRef = useRef<HTMLInputElement>(null);

    const handleRadiusChange = (event: ChangeEvent<HTMLInputElement>) => {
      setMaxRadius(Number(event.target.value));
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const switchContent = () => {
      setActiveContent((prevContent) =>
        prevContent === "normal" ? "realtime" : "normal"
      );
      toggleViewRealRev()
      setIsDropdownOpen(false);
    };
  
    useEffect(() => {
      console.log(radiusInputRef.current)
      setInfoString(<div>
        <span style={{ color: "red", fontWeight:"bold" }}><h2>Definisci le caratteristiche del candidato</h2></span>
        <p><h3>In questa sezione puoi definire tu le caratteristiche del candidato qualora non fosse già inserito a sistema (per esempio, nel caso si sia appena presentato in filiale per registrarsi).
                Usa i menu a tendina per definire le caratteristiche del candidato. I campi mandatori sono quelli per cui è presente l’asterisco, anche se è sufficiente inserire uno tra Esperienza candidato o Interesse candidato oltre che la città. Per l’Esperienza del candidato, puoi scegliere fra quelle presenti a sistema (derivati dalle ontologie standard Adecco) oppure puoi inserire un valore diverso che si avvicina maggiormente al ruolo per cui il candidato ha esperienza. In questo caso, la ricerca semantica ti suggerirà le posizioni lavorative aperte più affini al ruolo selezionato.
                Inoltre, usa la barra in basso per definire la distanza massima (in linea d’aria) entro cui cercare le posizioni lavorative.
                Clicca sul pulsante “Trova le offerte in linea” per visualizzare i risultati della ricerca.
                </h3></p>
      </div>)
      if (radiusInputRef.current) {
        const percentage = ((maxRadius - 0) / (200 - 0)) * 100;
        radiusInputRef.current.style.setProperty("--value", `${percentage}%`);
      }
    }, [maxRadius]);


    usePopulateField("/api/databricks/skills", setSkillsData);
    usePopulateField("/api/databricks/education", setEducationData);
    usePopulateField("/api/databricks/language", setLanguageData);
    usePopulateField("/api/databricks/license", setLicenseData);
    usePopulateField("/api/databricks/filterJob", setJobTitleData);
    usePopulateField("/api/databricks/filterJob", setExperienceData);
    usePopulateField("/api/databricks/filterCity", setCityData);
    usePopulateField("/api/databricks/filterCP", setCPData);
    

    const getBack = () => {
      toggleViewRealRev();
    }
  
    const handleTypeSelectJob = (e:any) => {
      setSelectedOptionJob(e ? e : null);

    };
  
    const handleTypeSelectBranch = (e:any) => {
      setSelectedOptionBranch(e ? e : null);
    };

    const handleTypeSelectSkill = (e:any) => {
      setSelectedOptionSkill(e ? e : null);
    };

    const handleTypeSelectEducation = (e:any) => {
      setSelectedOptionEducation(e ? e : null);
    };

    const handleTypeSelectLanguage = (e:any) => {
      setSelectedOptionLanguage(e ? e : null);
    };

    const handleTypeSelectLicense = (e:any) => {
      setSelectedOptionLicense(e ? e : null);
    };

    const handleTypeSelectExperience = (e:any) => {
        setSelectedOptionExperience(e ? e : null);
      };

    const handleTypeSelectCP = (e:any) => {
      setSelectedOptionCP(e ? e : null);
    };

    let dataSearchCheck = {"jobTitle":selectedOptionExperience, 
                      "city":selectedOptionBranch, 
                      "maxRadius":radiusInputRef.current?.value, 
                      "skills":selectedOptionSkill,
                      "education":selectedOptionEducation,
                      "language":selectedOptionLanguage,
                      "license":selectedOptionLicense,
                      "interest":selectedOptionJob,
                      "Categoria_protetta_filtro":selectedOptionCP}
                      

    const handleSearchCandidate = async() => {

      console.log(dataSearchCheck)

      let dataSearch:any = {}

      if ((dataSearchCheck['jobTitle'] === null || dataSearchCheck['jobTitle'] === "") && (dataSearchCheck['interest'] === null || dataSearchCheck['interest'] === "")){
        toast.error("Inserire Esperienza o Interesse Candidato",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }

      else if (dataSearchCheck['city'] === null || dataSearchCheck['city'].length === 0){
        toast.error("Inserire Città Candidato",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }

      if (dataSearchCheck['jobTitle'] === null|| dataSearchCheck['jobTitle'] === ""){
        dataSearch['jobTitle'] = dataSearchCheck['interest'][0].value;
        dataSearch['interest'] = null
      }

      else if (dataSearchCheck['interest'] === null || dataSearchCheck['interest'] === ""){
        dataSearch['jobTitle'] = dataSearchCheck['jobTitle'].value;
        dataSearch['interest'] = null
      }

      else {
        dataSearch['jobTitle'] = dataSearchCheck['jobTitle'].value;
        dataSearch['interest'] = null
      }

      console.log(dataSearch)

      if (dataSearchCheck['Categoria_protetta_filtro'] === null || dataSearchCheck['Categoria_protetta_filtro'].length === 0){
        dataSearch['Categoria_protetta_filtro'] = '';
      }
      else {
        dataSearch['Categoria_protetta_filtro'] = dataSearchCheck['Categoria_protetta_filtro'].value;
      }
    
      dataSearch['city'] = dataSearchCheck['city'].value;
      dataSearch['skills'] = dataSearchCheck['skills'].map(a => `${a.value}`);
      dataSearch['education'] = dataSearchCheck['education'].map(a => `${a.value}`);
      dataSearch['language'] = dataSearchCheck['language'].map(a => `${a.value}`);
      dataSearch['license'] = dataSearchCheck['license'].map(a => `${a.value}`);
      dataSearch['maxRadius'] = radiusInputRef.current?.value
      setDataPost(dataSearch)
      setHeaders(Object.keys(dataSearchCheck));
      setIsMatchingRealRevResult(true);
    }

  return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
    <div>
    <div>
      {isMatchingRealTimeRevResult ? 
      <div>
        <div>
          <RealtimeRevMatch dataSearch={dataPost} headers={headers} 
          setSelectedOptionJob={setSelectedOptionExperience} 
          setSelectedOptionBranch={setSelectedOptionBranch} 
          isMatchingRealTimeRevResult={isMatchingRealTimeRevResult} 
          setIsMatchingRealRevResult={setIsMatchingRealRevResult} 
          checkList={checkList} setCheckList={setCheckList}
          explain={explain} 
          setExplain={setExplain}
          isDetails={isDetails} iqg={iqg} email={email} emailC={emailC}
          setDetails={setDetails} setIqg={setIqg} setEmail={setEmail} setEmailC={setEmailC}
          emails={emails} setEmails={setEmails} emailsC={emailsC} setEmailsC={setEmailsC}
          questions={questions} setQuestions={setQuestions}
          headersNew={headersNew} setHeadersNew={setHeadersNew}
          anagrafiche={anagrafiche}
          setAnagrafiche={setAnagrafiche}
          interviste={interviste}
          setInterviste={setInterviste}
          ordini={ordini}
          setOrdini={setOrdini}
          anagrafica={anagrafica}
          setAnagrafica={setAnagrafica}
          ordine={ordine}
          setOrdine={setOrdine}
          intervista={intervista}
          setIntervista={setIntervista}
          cv={cv}
          setCv={setCv}
          codFisc={codFisc}
          setCodFisc={setCodFisc}
          timeAnagrafica={timeAnagrafica}
          setTimeAnagrafica={setTimeAnagrafica}
          timeOrdine={timeOrdine}
          setTimeOrdine={setTimeOrdine}
          timeIntervista={timeIntervista}
          setTimeIntervista={setTimeIntervista}
          timeCvs={timeCvs}
          setTimeCvs={setTimeCvs}
          cvs={cvs}
          setCvs={setCvs}
          urlPDF={urlPDF}
          setUrlPDF={setUrlPDF}
          isAI={isAI}
          setIsAI={setIsAI}
          matches={matches}
          setMatches={setMatches}
          jobInfo={jobInfo}
          setJobInfo={setJobInfo}
          jobInfos={jobInfos}
          setJobInfos={setJobInfos}
          timeJobInfo={timeJobInfo}
          setTimeJobInfo={setTimeJobInfo}></RealtimeRevMatch>
        </div>
      </div>:
      <div>
        <div id="menu-toggle-mobile">
        <div
          className={`container-toggle-dropdown mobile ${
            isDropdownOpen ? "active" : ""
          }`}
          onClick={toggleDropdown}
        >
          <div>
            {activeContent === "realtime" ? (
              <>
                <h4 className="m0">Candidati disponibili</h4>
                <p className="m0">Cerca tra i candidati disponibili</p>
              </>
            ) : (
              <>
                <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </>
            )}
          </div>
          <img
            width={12}
            src={isDropdownOpen ? chevronUp : chevronDown}
            alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
            className="chevron-icon"
          />
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu-mobile">
            {activeContent === "normal" ? (
              <div onClick={switchContent}>
                <h4 className="m0">Candidati disponibili</h4>
                <p className="m0">Cerca tra i candidati disponibili</p>
              </div>
            ) : (
              <div onClick={switchContent}>
                <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="slot-col">
      <table style={{width:"100%",textAlign:"center"}}>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
            <p className="bold">Esperienza Candidato <strong style={{ color: '#DA291C' }}>*</strong></p>
              <CreatableSelect 
              options={experienceData} 
              value={selectedOptionExperience}
              onChange={handleTypeSelectExperience}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Inserisci Esperienza Candidato"
              isDisabled={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Interesse Candidato <strong style={{ color: '#DA291C' }}>*</strong></p>
            <Select 
            isMulti
            options={jobTitleData} 
            value={selectedOptionJob}
            onChange={handleTypeSelectJob}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Interesse Candidato"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Lingue Candidato</p>
          <Select 
            options={languageData} 
            value={selectedOptionLanguage}
            isMulti
            onChange={handleTypeSelectLanguage}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Lingua"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Città <strong style={{ color: '#DA291C' }}>*</strong></p>
            <Select 
            options={cityData} 
            onChange={handleTypeSelectBranch}
            value={selectedOptionBranch}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Città"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Skill Candidato</p>
            <Select 
            options={skillsData} 
            value={selectedOptionSkill}
            isMulti
            onChange={handleTypeSelectSkill}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Skill"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Education Candidato</p>
            <Select 
            options={educationData} 
            value={selectedOptionEducation}
            isMulti
            onChange={handleTypeSelectEducation}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Education"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Categoria Protetta</p>
            <Select 
            options={cpData} 
            value={selectedOptionCP}
            onChange={handleTypeSelectCP}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Offerta per Categoria Protetta?"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Patenti Candidato</p>
            <Select 
            options={licenseData} 
            value={selectedOptionLicense}
            isMulti
            onChange={handleTypeSelectLicense}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Patente"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        </table>
        <div className="filter">
          <p className="bold">Distanza</p>
          <div style={{marginTop:"5%",marginBottom:"20%"}}>
            <div id="radius-selector">
                <p className="bold"></p>
                <p>{maxRadius} km</p>
                <input
                  type="range"
                  id="radius"
                  name="radius"
                  min="0"
                  max="200"
                  step="5"
                  value={maxRadius}
                  onChange={handleRadiusChange}
                  ref={radiusInputRef}
                />
              </div>
          </div>
          <div className="button-position">
          <button className="cta-red-realtime" onClick={handleSearchCandidate}>
                {"Trova le offerte in linea"}
          </button>
          <button className="cta-red-realtime-right" onClick={resetAllFields}>
                {"Resetta i filtri"}
          </button>
          </div>
        </div>
        <div style={{marginTop:"10%"}}>
              <div style={{textAlign:"center"}}>
              <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
          </div>
      </div>
      </div>}
    </div>
    </div>
      } 
    </div>
  );
}

export default RealTimeRev;
