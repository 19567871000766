import { createContext, useState, useContext, ReactNode } from "react";

interface IsInfoPageContextType {
  isInfoPage: boolean;
  toggleViewInfoPage: () => void;
}

const IsInfoPageContext = createContext<
IsInfoPageContextType | undefined
>(undefined);

export const useIsInfoPage = () => {
  const context = useContext(IsInfoPageContext);
  if (!context) {
    throw new Error(
      "Put in provider",
    );
  }
  return context;
};

export const IsInfoPageProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_INFO =
    process.env.REACT_APP_IS_INFO ?? "false";
  const [isInfoPage, setIsInfoPage] = useState(
    IS_INFO === "false",
  );

  const toggleViewInfoPage = () => {
    setIsInfoPage(!isInfoPage);
  };

  return (
    <IsInfoPageContext.Provider value={{ isInfoPage, toggleViewInfoPage}}>
      {children}
    </IsInfoPageContext.Provider>
  );
};
