import { useIsInfoPage } from "../../context/isInfoPage";
import "./InfoPage.css";

const InfoPage = (props:any) => {
    const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
    const getBack = () => {
        toggleViewInfoPage();
    }
    return (
        <div className="spacing-info">
            <pre>
                <p className="format-p-info">{props.infoString}</p>
            </pre>
            <div className="div-center">
                <button className="cta-red-mat" onClick={getBack}>
                                    INDIETRO
                </button>
            </div>
        </div>
    )
}

export default InfoPage