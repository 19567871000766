import "./RealTime.css";
import { useIsMatchingRealTime } from "../../../context/isMatchingRealTime";
import { useIsMatchingRealTimeResult } from "../../../context/isMatchingRealTimeResult";
import { useMemo, useState, useRef, ChangeEvent, useEffect } from "react";
import iconClose from "../../../images/icon-close.svg";
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { _post } from "../../../../apiClient";
import LoaderComp from "../../../loader/Loader"
import usePopulateField from "../../../hooks/usePopulateFields";
import Select from 'react-select'
import { ConstructionOutlined } from "@mui/icons-material";
import RealTimeMatch from "../RealTimeMatch/RealTimeMatch";
import { toast } from "react-toastify";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import CreatableSelect from "react-select/creatable";
import { useRealTimeDirectInputFieldsContext } from "../../../context/RealTimeDirectInputFields";
import { useIsInfoPage } from "../../../context/isInfoPage";
import { useInfoString } from "../../../context/infoString";
import InfoPage from "../../Info/InfoPage";

const RealTime = () => { 
    const { isMatchingRealTime, toggleViewReal } = useIsMatchingRealTime();
    const { isInfoPage, toggleViewInfoPage } = useIsInfoPage();
    const { infoString, setInfoString } = useInfoString();
    const { selectedOptionJob, 
            setSelectedOptionJob,
            selectedOptionCandidateState, 
            setSelectedOptionCandidateState,
            selectedOptionEta,
            setSelectedOptionEta,
            selectedOptionCP,
            setSelectedOptionCP,
            selectedOptionDA,
            setSelectedOptionDA,
            selectedOptionBranch,
            setSelectedOptionBranch,
            selectedOptionEducation,
            setSelectedOptionEducation,
            selectedOptionSkill,
            setSelectedOptionSkill,
            selectedOptionLanguage,
            setSelectedOptionLanguage,
            selectedOptionLicense,
            setSelectedOptionLicense,
            maxRadius,
            setMaxRadius,
            jobTitleData,
            setJobTitleData,
            candidateStateData,
            setCandidateStateData,
            etaData,
            setEtaData,
            cpData,
            setCPData,
            daData,
            setDAData,
            cityData,
            setCityData,
            skillsData,
            setSkillsData,
            educationData,
            setEducationData,
            languageData,
            setLanguageData,
            licenseData,
            setLicenseData,
            resetAllFields} = useRealTimeDirectInputFieldsContext();
    const { isMatchingRealTimeResult, toggleView } = useIsMatchingRealTimeResult();
    const [headers, setHeaders] = useState({});
    const [dataPost, setDataPost] = useState<any>({});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [checkList, setCheckList] = useState<any[]>([])
    const [isDetails, setDetails] = useState(false);
    const [iqg, setIqg] = useState(false);
    const [email, setEmail] = useState(false);
    const [emailC, setEmailC] = useState(false);
    const [emails, setEmails] = useState([]);
    const [emailsC, setEmailsC] = useState([]);
    const [questions, setQuestions] = useState([])

    const [candidates, setCandidates] = useState<any>([])
    const [explain, setExplain] = useState<any>({})

    const [activeContent, setActiveContent] = useState(
      isMatchingRealTime ? "realtime" : "normal"
    );

    const [headersNew, setHeadersNew] = useState<any>({})

    const [anagrafiche, setAnagrafiche] = useState([])
    const [interviste, setInterviste] = useState([])
    const [ordini, setOrdini] = useState([])
    const [anagrafica, setAnagrafica] = useState(false);
    const [ordine, setOrdine] = useState(false);
    const [intervista, setIntervista] = useState(false);
    const [cv, setCv] = useState(false);
    const [timeAnagrafica, setTimeAnagrafica] = useState(false)
    const [timeOrdine, setTimeOrdine] = useState(false)
    const [timeCvs, setTimeCvs] = useState(false)
    const [timeIntervista, setTimeIntervista] = useState(false)
    const [cvs, setCvs] = useState("")
    const [urlPDF, setUrlPDF] = useState("");
    const [isAI, setIsAI] = useState(false)
    const [codFisc, setCodFisc] = useState("");
    const [isPenalized, setIsPenalized] = useState<any>(1)
    const [isOrderLastActivity, setIsOrderLastActivity] = useState<any>(1)

    console.log(selectedOptionJob)
    const radiusInputRef = useRef<HTMLInputElement>(null);

    const handleRadiusChange = (event: ChangeEvent<HTMLInputElement>) => {
      setMaxRadius(Number(event.target.value));
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const switchContent = () => {
      setActiveContent((prevContent) =>
        prevContent === "normal" ? "realtime" : "normal"
      );
      toggleViewReal();
      setIsDropdownOpen(false);
    };
  
    useEffect(() => {
      console.log(radiusInputRef.current)
      setInfoString(<div>
        <span style={{ color: "red", fontWeight:"bold" }}><h2>Definisci l’offerta di lavoro</h2></span>
        <p><h3>In questa sezione puoi definire tu le caratteristiche dell’offerta di lavoro qualora non fosse già inserita a sistema.
                Usa i menu a tendina per definire le caratteristiche della posizione lavorativa. I campi mandatori sono quelli per cui è presente l’asterisco (Categoria professionale e città). Per la categoria professionale, puoi scegliere fra quelle presenti a sistema (derivati dalle ontologie standard Adecco) oppure puoi inserire un valore diverso che si avvicina maggiormente alla mansione da ricoprire per il ruolo di interesse. In questo caso, la ricerca semantica ti suggerirà le posizioni lavorative aperte più affini alla mansione ricercata.
                Inoltre, usa la barra in basso per definire la distanza massima (in linea d’aria) entro cui cercare i candidati.
                Clicca sul pulsante “Trova i profili in linea” per visualizzare i risultati della ricerca.

                </h3></p>
      </div>)
      if (radiusInputRef.current) {
        const percentage = ((maxRadius - 0) / (200 - 0)) * 100;
        radiusInputRef.current.style.setProperty("--value", `${percentage}%`);
      }
    }, [maxRadius]);


    usePopulateField("/api/databricks/skills", setSkillsData);
    usePopulateField("/api/databricks/education", setEducationData);
    usePopulateField("/api/databricks/language", setLanguageData);
    usePopulateField("/api/databricks/license", setLicenseData);
    usePopulateField("/api/databricks/filterJob", setJobTitleData);
    usePopulateField("/api/databricks/filterCity", setCityData);
    usePopulateField("/api/databricks/filterCity", setCityData);
    usePopulateField("/api/databricks/filterEta", setEtaData);
    usePopulateField("/api/databricks/filterCP", setCPData);
    usePopulateField("/api/databricks/filterDA", setDAData);
    usePopulateField("/api/databricks/filterCandidateState", setCandidateStateData);

    const getBack = () => {
      toggleView(false);
    }
  
    const handleTypeSelectJob = (e:any) => {
      setSelectedOptionJob(e ? e : null);
      
    };
  
    const handleTypeSelectBranch = (e:any) => {
      setSelectedOptionBranch(e ? e: null);
    };

    const handleTypeSelectSkill = (e:any) => {
      setSelectedOptionSkill(e ? e : null);
    };

    const handleTypeSelectEducation = (e:any) => {
      setSelectedOptionEducation(e ? e : null);
    };

    const handleTypeSelectLanguage = (e:any) => {
      setSelectedOptionLanguage(e ? e : null);
    };

    const handleTypeSelectLicense = (e:any) => {
      setSelectedOptionLicense(e ? e : null);
    };

    const handleTypeSelectEta = (e:any) => {
      setSelectedOptionEta(e ? e : null);
    };

    const handleTypeSelectCP = (e:any) => {
      setSelectedOptionCP(e ? e : null);
    };

    const handleTypeSelectDA = (e:any) => {
      setSelectedOptionDA(e ? e : null);
    };

    const handleTypeSelectCandidateState = (e:any) => {
      setSelectedOptionCandidateState(e);
    };

    let dataSearch = {"jobTitle":selectedOptionJob, 
                      "city":selectedOptionBranch, 
                      "maxRadius":radiusInputRef.current?.value, 
                      "skills":selectedOptionSkill,
                      "education":selectedOptionEducation,
                      "language":selectedOptionLanguage,
                      "license":selectedOptionLicense,
                      "classe_eta":selectedOptionEta,
                      "candidate_state":selectedOptionCandidateState,
                      "Categoria_protetta_filtro":selectedOptionCP,
                      "Dote_attiva_filtro":selectedOptionDA,
                      "isPenalty":isPenalized,
                      "isOrderLastActivity":isOrderLastActivity}

    const handleSearchCandidate = async() => {

      console.log(dataSearch)

      if (dataSearch['jobTitle'] === null || dataSearch['jobTitle'].length === 0){
        toast.error("Inserire Categoria Professionale Offerta",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }

      else if ( dataSearch['city'] === null || dataSearch['city'].length  === 0){
        toast.error("Inserire Città Offerta",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }
      
      dataSearch['jobTitle'] = dataSearch['jobTitle'].value;
      dataSearch['city'] = dataSearch['city'].value;
      if (dataSearch['classe_eta'] === null || dataSearch['classe_eta'].length  === 0){
        dataSearch['classe_eta'] = '';
      }
      else {
        dataSearch['classe_eta'] = dataSearch['classe_eta'].value;
      }
      if (dataSearch['Categoria_protetta_filtro'] === null || dataSearch['Categoria_protetta_filtro'].length === 0){
        dataSearch['Categoria_protetta_filtro'] = '';
      }
      else {
        dataSearch['Categoria_protetta_filtro'] = dataSearch['Categoria_protetta_filtro'].value;
      }
      if (dataSearch['Dote_attiva_filtro'] === null || dataSearch['Dote_attiva_filtro'].length === 0){
        dataSearch['Dote_attiva_filtro'] = '';
      }
      else {
        dataSearch['Dote_attiva_filtro'] = dataSearch['Dote_attiva_filtro'].value;
      }
      if (dataSearch['candidate_state'] === null || dataSearch['candidate_state'].length === 0){
        dataSearch['candidate_state'] = '';
      }
      else {
        if (dataSearch['candidate_state'].value === 'Certificato') {
          dataSearch['candidate_state'] = 'I ';
        }

        else if (dataSearch['candidate_state'].value === 'Candidato Non Idoneo') {
          dataSearch['candidate_state'] = 'NI';
        }

        else if (dataSearch['candidate_state'].value === 'Da Valutare') {
          dataSearch['candidate_state'] = 'DV';
        }
      }
      dataSearch['skills'] = dataSearch['skills'].map(a => `${a.value}`);
      dataSearch['education'] = dataSearch['education'].map(a => `${a.value}`);
      dataSearch['language'] = dataSearch['language'].map(a => `${a.value}`);
      dataSearch['license'] = dataSearch['license'].map(a => `${a.value}`);
      dataSearch['maxRadius'] = radiusInputRef.current?.value
      setDataPost(dataSearch)
      setHeaders(Object.keys(dataSearch));
      toggleView(true);
    }

  return (
    <div>
      {isInfoPage ? 
      <div>
        <InfoPage infoString={infoString}></InfoPage>
      </div>
      :
    <div>
    <div>
      {isMatchingRealTimeResult ? 
      <div>
        <div>
          <RealTimeMatch dataSearch={dataPost} headers={headers} setSelectedOptionJob={setSelectedOptionJob} 
          setSelectedOptionBranch={setSelectedOptionBranch} checkList={checkList} 
          setCheckList={setCheckList}
          candidates={candidates} setCandidates={setCandidates} explain={explain} 
          setExplain={setExplain}
          isDetails={isDetails} iqg={iqg} email={email} emailC={emailC}
          setDetails={setDetails} setIqg={setIqg} setEmail={setEmail} setEmailC={setEmailC}
          emails={emails} setEmails={setEmails} emailsC={emailsC} setEmailsC={setEmailsC}
          questions={questions} setQuestions={setQuestions}
          headersNew={headersNew} setHeadersNew={setHeadersNew}
          anagrafiche={anagrafiche}
          setAnagrafiche={setAnagrafiche}
          interviste={interviste}
          setInterviste={setInterviste}
          ordini={ordini}
          setOrdini={setOrdini}
          anagrafica={anagrafica}
          setAnagrafica={setAnagrafica}
          ordine={ordine}
          setOrdine={setOrdine}
          intervista={intervista}
          setIntervista={setIntervista}
          cv={cv}
          setCv={setCv}
          codFisc={codFisc}
          setCodFisc={setCodFisc}
          timeAnagrafica={timeAnagrafica}
          setTimeAnagrafica={setTimeAnagrafica}
          timeOrdine={timeOrdine}
          setTimeOrdine={setTimeOrdine}
          timeIntervista={timeIntervista}
          setTimeIntervista={setTimeIntervista}
          timeCvs={timeCvs}
          setTimeCvs={setTimeCvs}
          cvs={cvs}
          setCvs={setCvs}
          urlPDF={urlPDF}
          setUrlPDF={setUrlPDF}
          isAI={isAI}
          setIsAI={setIsAI}
          isPenalized={isPenalized}
          setIsPenalized={setIsPenalized}
          isOrderLastActivity={isOrderLastActivity}
          setIsOrderLastActivity={setIsOrderLastActivity}></RealTimeMatch>
        </div>
      </div>:
      <div>
        <div id="menu-toggle-mobile">
        <div
          className={`container-toggle-dropdown mobile ${
            isDropdownOpen ? "active" : ""
          }`}
          onClick={toggleDropdown}
        >
          <div>
            {activeContent === "realtime" ? (
              <>
                <h4 className="m0">Opportunità aperte</h4>
                <p className="m0">Cerca tra le opportunità disponibili</p>
              </>
            ) : (
              <>
                <h4 className="m0">Definisci l’offerta di lavoro</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </>
            )}
          </div>
          <img
            width={12}
            src={isDropdownOpen ? chevronUp : chevronDown}
            alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
            className="chevron-icon"
          />
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu-mobile">
            {activeContent === "normal" ? (
              <div onClick={switchContent}>
                <h4 className="m0">Opportunità aperte</h4>
                <p className="m0">Cerca tra le opportunità disponibili</p>
              </div>
            ) : (
              <div onClick={switchContent}>
                <h4 className="m0">Definisci l’offerta di lavoro</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="slot-col">
      <table style={{width:"100%",textAlign:"center"}}>
        <tr>
        <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
        <p className="bold">Categoria Professionale <strong style={{ color: '#DA291C' }}>*</strong></p>
            <CreatableSelect 
            options={jobTitleData} 
            value={selectedOptionJob}
            onChange={handleTypeSelectJob}
            className="basic-single"
            classNamePrefix="select"
            placeholder = "Seleziona Categoria"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Città <strong style={{ color: '#DA291C' }}>*</strong></p>
            <Select 
            options={cityData} 
            value={selectedOptionBranch}
            onChange={handleTypeSelectBranch}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Città"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
        <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
        <p className="bold">Skill Richieste</p>
            <Select 
            options={skillsData} 
            value={selectedOptionSkill}
            isMulti
            onChange={handleTypeSelectSkill}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Skill"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Education Richieste</p>
            <Select 
            options={educationData} 
            value={selectedOptionEducation}
            isMulti
            onChange={handleTypeSelectEducation}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Education"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Lingue Richieste</p>
            <Select 
            options={languageData} 
            value={selectedOptionLanguage}
            isMulti
            onChange={handleTypeSelectLanguage}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Lingua"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Patenti Richieste</p>
            <Select 
            options={licenseData} 
            value={selectedOptionLicense}
            isMulti
            onChange={handleTypeSelectLicense}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Patente"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
        <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Dote Attiva</p>
            <Select 
            options={daData} 
            value={selectedOptionDA}
            onChange={handleTypeSelectDA}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Ha Dote Attiva?"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Categoria Protetta</p>
            <Select 
            options={cpData} 
            value={selectedOptionCP}
            onChange={handleTypeSelectCP}
            className="basic-single"
            classNamePrefix="select"
            placeholder="E' Categoria Protetta?"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
        <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Stato Candidato</p>
            <Select 
            options={candidateStateData} 
            value={selectedOptionCandidateState}
            onChange={handleTypeSelectCandidateState}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Stato Candidato"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Classe Età</p>
            <Select 
            options={etaData} 
            value={selectedOptionEta}
            onChange={handleTypeSelectEta}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Classe Età Candidato"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
      </table>
        <div className="filter">
        <p className="bold">Distanza</p>
          <div style={{marginTop:"5%",marginBottom:"20%"}}>
            <div id="radius-selector">
                <p className="bold"></p>
                <p>{maxRadius} km</p>
                <input
                  type="range"
                  id="radius"
                  name="radius"
                  min="0"
                  max="200"
                  step="5"
                  value={maxRadius}
                  onChange={handleRadiusChange}
                  ref={radiusInputRef}
                />
              </div>
          </div>
          <div className="button-position">
          <button className="cta-red-realtime" onClick={handleSearchCandidate}>
                {"Trova i profili in linea"}
          </button>
          <button className="cta-red-realtime-right" onClick={resetAllFields}>
                {"Resetta i filtri"}
          </button>
          </div>
        </div>
        <div>
              <div style={{textAlign:"center"}}>
              <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
        </div>
      </div>
      </div>}
    </div>
    </div>
      }
    </div>
  );
}

export default RealTime;
